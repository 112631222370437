import {
  AttachFile as AttachFileIcon,
  Favorite as FavoriteIcon,
  InsertDriveFile as FileIcon,
  Send as SendIcon,
  Mic as MicIcon,
  QuestionAnswer as QuestionAnswerIcon,
  LightbulbOutlined as LightbulbOutlinedIcon,
  Lightbulb as LightbulbIcon,
} from "@mui/icons-material";
import { Badge, Box, IconButton, Paper, TextField, Button, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { StyledComponentsThemWrapper } from "./StyledComponentsThemWrapper";
import { trackChatEvent } from '../utils/analytics';

const FooterContainer = styled(Paper)`
  position: relative;
  padding: ${({ theme }) => theme.customSpacing.footerPadding};
  background-color: ${({ theme }) => theme.palette.footer.background};
  border-top: 2px solid ${({ theme }) => theme.palette.primary.main};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.customSpacing.footerPaddingMobile};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    /* Add padding for iOS devices */
    padding-bottom: env(safe-area-inset-bottom, 0px);
  }
`;

const FooterSpacer = styled.div`
  display: none;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: block;
    height: 80px;
    width: 100%;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    gap: 4px;
    position: relative;
    z-index: 2;
    
    /* Prevent container from being pushed up by keyboard */
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    
    /* Fix for Firefox mobile */
    @-moz-document url-prefix() {
      position: sticky;
      bottom: 0;
    }
  }
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    font-size: ${({ theme }) => theme.typography.inputFontSize};
    border-radius: 22px;
    padding: ${({ theme }) => theme.customSpacing.inputPadding};
    background-color: #ffffff;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    transition: all 0.3s ease;
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    
    &:hover {
      border-color: ${({ theme }) => theme.palette.primary.dark};
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    }

    &.Mui-focused {
      border-color: ${({ theme }) => theme.palette.primary.dark};
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      transform: scale(1.01);
    }

    textarea {
      -webkit-user-select: text;
      -webkit-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      
      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        transform: translateZ(0);
        backface-visibility: hidden;
        -webkit-font-smoothing: subpixel-antialiased;
        padding: 6px 10px;
        min-height: 20px;
        line-height: 1.3;
        
        font-size: 16px !important;
        
        &::-webkit-contacts-auto-fill-button,
        &::-webkit-credentials-auto-fill-button {
          visibility: hidden;
          display: none !important;
          pointer-events: none;
          height: 0;
          width: 0;
          margin: 0;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    .MuiOutlinedInput-root {
      /* Improve input handling on mobile */
      -webkit-appearance: none;
      border-radius: 18px;
      font-size: 16px !important; /* Prevent iOS zoom */
      line-height: 1.3;
      padding: 8px 12px;
      
      /* Better handling of keyboard appearance */
      position: relative;
      z-index: 1;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
      
      textarea {
        /* Prevent iOS zoom and improve scrolling */
        font-size: 16px !important;
        max-height: 120px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        
        /* Disable unwanted browser features */
        resize: none;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        
        /* Fix Firefox mobile issues */
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`;

const ActionButton = styled(IconButton)`
  width: ${({ theme }) => theme.sizing.buttonSize} !important;
  height: ${({ theme }) => theme.sizing.buttonSize} !important;
  margin-right: ${({ theme }) => theme.customSpacing.buttonMargin} !important;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 36px !important;  /* Slightly smaller on mobile */
    height: 36px !important;
    margin-right: 4px !important;
  }
`;

const ChatFooter = ({
  onSendMessage,
  onSendFile,
  onSendAudio,
  isLoading,
  isHumanControlActive,
  inputValue,
  setInputValue,
  predictedQuestions,
  onQuestionClick,
  isMobile
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showQuestions, setShowQuestions] = useState(false);
  const [hasSeenQuestions, setHasSeenQuestions] = useState(false);
  const previousQuestionsRef = useRef([]);
  const textAreaRef = useRef(null);
  const footerRef = useRef(null);

  // Check for new questions
  const hasNewQuestions = useMemo(() => {
    const hasQuestions = predictedQuestions && predictedQuestions.length > 0;
    const questionsChanged = JSON.stringify(predictedQuestions) !== JSON.stringify(previousQuestionsRef.current);
    return hasQuestions && (questionsChanged || !hasSeenQuestions);
  }, [predictedQuestions, hasSeenQuestions]);

  // Update previous questions ref when they change
  useEffect(() => {
    previousQuestionsRef.current = predictedQuestions;
  }, [predictedQuestions]);

  // Update the auto-resize effect to be more performant
  useEffect(() => {
    if (textAreaRef.current) {
      // Cache the current scroll position
      const scrollableParent = textAreaRef.current.closest('[style*="overflow: auto"]');
      const scrollTop = scrollableParent?.scrollTop;

      // Batch the height calculations
      requestAnimationFrame(() => {
        textAreaRef.current.style.height = "auto";
        const scrollHeight = textAreaRef.current.scrollHeight;
        textAreaRef.current.style.height = `${scrollHeight}px`;

        // Restore scroll position if needed
        if (scrollableParent && scrollTop) {
          scrollableParent.scrollTop = scrollTop;
        }
      });
    }
  }, [inputValue]);

  // Add a new placeholder that changes periodically
  const placeholders = [
    "Chiedi informazioni su un'auto...",
    "Prenota un test drive...",
    "Cerca auto disponibili...",
    "Domanda prezzi e offerte...",
    "Chiedi dettagli sui modelli..."
  ];
  
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPlaceholder(prev => {
        const currentIndex = placeholders.indexOf(prev);
        return placeholders[(currentIndex + 1) % placeholders.length];
      });
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  // Update handleFocus to be more efficient
  const handleFocus = () => {
    if (!isMobile) return; // Only run on mobile

    requestAnimationFrame(() => {
      const scrollableParent = textAreaRef.current?.closest('[style*="overflow: auto"]');
      if (scrollableParent) {
        scrollableParent.scrollTo({
          top: scrollableParent.scrollHeight,
          behavior: "auto" // Change to "auto" for smoother performance
        });
      }
    });
  };

  // Update handleBlur to be more efficient
  const handleBlur = () => {
    if (!isMobile) return; // Only run on mobile

    const scrollableParent = textAreaRef.current?.closest('[style*="overflow: auto"]');
    if (scrollableParent) {
      scrollableParent.scrollTo({
        top: scrollableParent.scrollHeight,
        behavior: "auto" // Change to "auto" for smoother performance
      });
    }
  };

  const handleToggleQuestions = () => {
    setShowQuestions(!showQuestions);
    if (!showQuestions) {
      setHasSeenQuestions(true);
    }
  };

  const handleSend = () => {
    if ((inputValue.trim() || selectedFile) && (!isLoading || isHumanControlActive)) {
      if (selectedFile) {
        trackChatEvent.attachFile({
          event: "asc_comm_submission",
          event_owner: "aitomotive",
          page_type: "home",
          comm_type: "chat",
          affiliation: "native",
          element_position: "bottom_right",
          department: "sales",
          affiliation_id: "bot",
          comm_status: "send",
          file_type: selectedFile.type,
        });
        onSendFile(selectedFile, inputValue);
        setSelectedFile(null);
      } else {
        trackChatEvent.sendMessage({
          event_owner: "aitomotive",
          page_type: "home",
          comm_type: "chat",
          affiliation: "native",
          element_position: "bottom_right",
          department: "sales",
          affiliation_id: "bot",
          comm_status: "send",
          message_length: inputValue.length,
        });
        onSendMessage(inputValue);
      }
      setInputValue("");

      if (textAreaRef.current) {
        textAreaRef.current.style.height = "auto";
        if (!isMobile) {
          textAreaRef.current.focus();
        }
      }
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        trackChatEvent.attachFile({
          event: "asc_comm_submission",
          event_owner: "aitomotive",
          page_type: "home",
          comm_type: "chat",
          affiliation: "native",
          element_position: "bottom_right",
          department: "sales",
          affiliation_id: "bot",
          comm_status: "send",
          file_type: file.type,
        });
        setSelectedFile(file);
      } else {
        alert(
          "Invalid file type or size. Please select a JPEG, PNG, or PDF file under 5MB."
        );
      }
    }
  };

  const handleQuestionClick = (question) => {
    setInputValue(question);
    setShowQuestions(false);
    setHasSeenQuestions(true);
    textAreaRef.current?.focus();
  };

  return (
    <StyledComponentsThemWrapper>
      <FooterSpacer />
      <FooterContainer elevation={3} ref={footerRef}>
        <InputContainer>
          <ActionButton
            component={motion.button}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleToggleQuestions}
            disabled={isLoading && !isHumanControlActive}
            aria-label="Show suggested questions"
            sx={{
              backgroundColor: showQuestions ? '#e3f2fd' : '#fff',
              color: hasNewQuestions ? '#ffc107' : '#bdbdbd',
              borderRadius: '50%',
              border: '1px solid #e0e0e0',
              '&:hover': {
                backgroundColor: '#e3f2fd',
              },
              transition: 'all 0.3s ease',
              '&.Mui-disabled': {
                color: '#bdbdbd',
              },
            }}
          >
            <motion.div
              animate={hasNewQuestions ? {
                scale: [1, 1.2, 1],
                rotate: [0, 15, -15, 0],
              } : {}}
              transition={{
                duration: 0.5,
                repeat: hasNewQuestions ? Infinity : 0,
                repeatDelay: 2
              }}
            >
              {hasNewQuestions ? <LightbulbIcon /> : <LightbulbOutlinedIcon />}
            </motion.div>
          </ActionButton>

          {showQuestions && predictedQuestions && predictedQuestions.length > 0 && (
            <Box
              sx={{
                position: 'absolute',
                bottom: '100%',
                left: 0,
                right: 0,
                backgroundColor: '#fff',
                borderRadius: '12px',
                boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
                padding: '12px',
                marginBottom: '8px',
                zIndex: 1100,
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#666',
                  marginBottom: '8px',
                  paddingLeft: '4px',
                }}
              >
                Domande suggerite:
              </Typography>
              {predictedQuestions.map((question, index) => (
                <Button
                  key={index}
                  fullWidth
                  onClick={() => handleQuestionClick(question)}
                  sx={{
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    mb: index < predictedQuestions.length - 1 ? 1 : 0,
                    whiteSpace: 'normal',
                    height: 'auto',
                    padding: '10px 16px',
                    borderRadius: '8px',
                    color: '#000000',
                    backgroundColor: '#f5f5f5',
                    textTransform: 'none',
                    fontWeight: 'normal',
                    fontSize: '15px',
                    '&:hover': {
                      backgroundColor: '#eeeeee',
                    },
                  }}
                >
                  {question}
                </Button>
              ))}
            </Box>
          )}

          <StyledTextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder={currentPlaceholder}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            multiline
            rows={1}
            inputRef={textAreaRef}
            autoComplete="new-password"
            data-form-type="other"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            inputProps={{
              'data-lpignore': 'true',
              'data-form-type': 'other',
              'aria-autocomplete': 'none',
              'role': 'presentation',
              enterKeyHint: 'send',
              'data-chrome-assistant': 'disabled',
              style: {
                WebkitUserSelect: 'text',
                WebkitAppearance: 'none',
                resize: 'none'
              }
            }}
            sx={{
              flex: 1,
              marginRight: "8px",
              maxHeight: "none",
              position: "relative",
              zIndex: 1050,
              transition: "all 0.3s ease",
              '& .MuiOutlinedInput-root': {
                [`@media (min-width: ${theme => theme.breakpoints.mobile})`]: {
                  '&:not(.Mui-focused):not(:hover)::after': {
                    content: '"Scrivi qui"',
                    position: 'absolute',
                    right: '45px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: '#666',
                    animation: 'pulse 2s infinite',
                    fontSize: '14px',
                    opacity: inputValue ? 0 : 0.7,
                  }
                }
              },
              '& .MuiInputBase-input': {
                '-webkit-appearance': 'none',
                'appearance': 'none',
                '-webkit-tap-highlight-color': 'transparent',
                'data-gramm': 'false',
                'data-gramm_editor': 'false',
                'data-enable-grammarly': 'false',
                '&::-webkit-input-assistant-container': {
                  display: 'none !important'
                },
                '&::-webkit-input-panel': {
                  display: 'none !important'
                },
                '&::-webkit-input-panel-section': {
                  display: 'none !important'
                }
              }
            }}
            disabled={isLoading && !isHumanControlActive}
          />

          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFileSelect}
            disabled={isLoading && !isHumanControlActive}
            accept=".jpg,.jpeg,.png"
          />

          <ActionButton
            component={motion.button}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleSend}
            disabled={(isLoading && !isHumanControlActive) && (!inputValue.trim())}
            aria-label="Send message"
            sx={{
              backgroundColor: "#25D366",
              color: "#fff",
              borderRadius: "50%",
              opacity: inputValue.trim() ? 1 : 0.6,
              marginRight: { xs: '2px', sm: '8px' },
            }}
          >
            <SendIcon />
          </ActionButton>
        </InputContainer>
      </FooterContainer>
    </StyledComponentsThemWrapper>
  );
};

export default ChatFooter;