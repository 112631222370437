import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardMedia, CardContent, Button, Chip, CircularProgress } from '@mui/material';
import { FaTachometerAlt, FaCalendarAlt, FaEuroSign, FaInfoCircle, FaEye, FaCheckCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';
import VehicleDialog from './ChatBody/CustomCarousel/VehicleDialog';
import { fetchVehicleData } from '../api/fetchVehicleData';
import { trackChatEvent } from '../utils/analytics';
import { useWebSocketContext } from '../context/Theme';

const MotionButton = motion(Button);

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString("it-IT", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

/**
 * A simple horizontally-scrollable car carousel,
 * specialized for the SearchPanel.
 */
const SearchPanelCarousel = ({ items = [] }) => {
  const { handleSend, isLoading, setIsLoading } = useWebSocketContext();
  const [open, setOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [showFullScreenGallery, setShowFullScreenGallery] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadingImages, setLoadingImages] = useState(true);

  useEffect(() => {
    setLoadingImages(true);
    setImagesLoaded(false);

    const imagePromises = items.map((car) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = car.image;
        img.onload = () => resolve();
        img.onerror = () => resolve(); // Also resolve on error to prevent hanging
      });
    });

    Promise.all(imagePromises).then(() => {
      setImagesLoaded(true);
      setLoadingImages(false);
    });
  }, [items]); // Reset loading state when items change

  const handleBookVisit = (car) => {
    const message = `Vorrei prenotare una visita per: ${car.title}, veicolo ${car.vehicleid}`;
    trackChatEvent.bookVisit(car.vehicleid, car.title);
    
    handleSend(message, null, null, {
      type: 'chat_message',
      sender: 'user'
    });
  };

  const handleInfoRequest = (car) => {
    const message = `Vorrei più informazioni su: ${car.title}, veicolo ${car.vehicleid}`;
    trackChatEvent.sendMessage(message.length);
    
    handleSend(message, null, null, {
      type: 'chat_message',
      sender: 'user'
    });
  };

  const handleImageClick = async (vehicle) => {
    trackChatEvent.viewVehicleDetails(vehicle.vehicleid, vehicle.title);
    setOpen(true);
    setIsLoading(true);
    setVehicleData(null);
    setSelectedVehicleId(vehicle.vehicleid);
    setShowFullScreenGallery(true);
    
    try {
      const data = await fetchVehicleData(vehicle.vehicleid);
      setVehicleData(data);
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDetailsClick = async (vehicle) => {
    trackChatEvent.viewVehicleDetails(vehicle.vehicleid, vehicle.title);
    setOpen(true);
    setIsLoading(true);
    setVehicleData(null);
    setSelectedVehicleId(vehicle.vehicleid);
    setShowFullScreenGallery(false);
    
    try {
      const data = await fetchVehicleData(vehicle.vehicleid);
      setVehicleData(data);
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setShowFullScreenGallery(false);
  };

  if (loadingImages || !imagesLoaded) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '200px',
          width: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          mb: 3,
        }}
      >
        {items.map((car, idx) => (
          <Card
            key={idx}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              width: '100%',
              height: { xs: 'auto', sm: '220px' },
              borderRadius: '12px',
              overflow: 'hidden',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              '&:hover': {
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
              },
              bgcolor: '#fff',
              position: 'relative',
            }}
          >
            {car.badge && (
              <Chip
                label={car.badge}
                sx={{
                  position: "absolute",
                  top: 8,
                  left: 8,
                  zIndex: 1,
                  fontSize: "1rem",
                  height: "32px",
                  borderRadius: "8px",
                  fontWeight: 600,
                  backgroundColor: (theme) => theme.palette.v2?.primary || '#2196f3',
                  color: (theme) => theme.palette.v2?.textPrimary || '#fff',
                }}
              />
            )}
            {!car.available && (
              <Chip
                label="Non disponibile"
                sx={{
                  position: "absolute",
                  top: car.badge ? 48 : 8,
                  left: 8,
                  zIndex: 1,
                  backgroundColor: (theme) => theme.palette.error.main,
                  color: "white",
                  fontSize: "1rem",
                  height: "32px",
                  borderRadius: "8px",
                  fontWeight: 600,
                }}
              />
            )}

            <Box
              component={motion.div}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              sx={{
                width: { xs: '100%', sm: '280px' },
                height: { xs: '180px', sm: '220px' },
                cursor: 'pointer',
                overflow: 'hidden',
              }}
              onClick={() => handleImageClick(car)}
            >
              <CardMedia
                component="img"
                src={car.image}
                alt={car.title ?? 'Car image'}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
                onError={(e) => {
                  const fallbackLogo = localStorage.getItem("logo") || `${process.env.REACT_APP_DOMAIN}/media/company_logos/3_ms-icon-144x144.png`;
                  e.target.src = fallbackLogo;
                  e.target.style.objectFit = 'contain';
                  e.target.style.padding = '20px';
                  e.target.style.backgroundColor = '#f5f5f5';
                }}
              />
            </Box>
            <Box sx={{ 
              flex: 1, 
              display: 'flex',
              flexDirection: 'column',
              p: 1.5,
              justifyContent: 'space-between',
              gap: 0.5,
            }}>
              <Box>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    fontWeight: 800,
                    fontSize: {
                      xs: "1rem",
                      sm: "1.3rem",
                    },
                    lineHeight: 1.2,
                    marginBottom: "4px",
                    color: "#000",
                    background: "linear-gradient(135deg, rgba(224, 247, 250,0.2), rgba(224, 247, 250,0))",
                    display: "inline-block",
                    borderRadius: "4px",
                    paddingX: "4px",
                  }}
                >
                  {car.title}
                </Typography>

                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      flexWrap: "wrap",
                      backgroundColor: "rgba(224, 247, 250, 0.5)",
                      borderRadius: "6px",
                      padding: "4px 6px",
                      mb: 0.25,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                        display: "inline-flex",
                        alignItems: "center",
                        margin: 0,
                      }}
                    >
                      <FaEuroSign style={{ marginRight: "4px", fontSize: "1.1rem" }} />
                      {formatPrice(car.description2)}
                    </Typography>

                    {(() => {
                      const oldPrice = parseFloat(car.description4 || "0");
                      const newPrice = parseFloat(car.description2);
                      const hasDiscount = oldPrice > newPrice && oldPrice > 0;
                      if (!hasDiscount) return null;

                      const difference = oldPrice - newPrice;
                      const discountPercentage = Math.round((1 - newPrice / oldPrice) * 100);

                      return (
                        <>
                          <Typography
                            variant="body2"
                            sx={{
                              textDecoration: "line-through",
                              color: "#777",
                              display: "inline-flex",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <FaEuroSign style={{ fontSize: "0.8rem" }} />
                            {formatPrice(oldPrice)}
                          </Typography>
                          <Chip
                            label={`-${discountPercentage}%`}
                            size="small"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "0.75rem",
                              backgroundColor: "#03A9F4",
                              color: "#fff",
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 600,
                              color: "success.main",
                              display: "inline-flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <FaCheckCircle style={{ fontSize: "1rem" }} />
                            Risparmi {formatPrice(difference)} €
                          </Typography>
                        </>
                      );
                    })()}
                  </Box>

                  <Box sx={{ 
                    display: 'flex', 
                    gap: 2,
                    alignItems: 'center',
                    mb: 0.5,
                  }}>
                    {(() => {
                      const badgeLower = (car.badge || "").toLowerCase();
                      const isMileageAllowed =
                        badgeLower !== "nuovo" &&
                        badgeLower !== "km zero" &&
                        badgeLower !== "km0" &&
                        badgeLower !== "km 0";

                      if (car.description1 && isMileageAllowed) {
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <FaTachometerAlt size={14} color="#666" />
                            <Typography sx={{ fontSize: '0.9rem', color: '#666' }}>
                              {formatPrice(car.description1)} km
                            </Typography>
                          </Box>
                        );
                      }
                      return null;
                    })()}
                    
                    {car.description3 && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <FaCalendarAlt size={14} color="#666" />
                        <Typography sx={{ fontSize: '0.9rem', color: '#666' }}>
                          {car.description3}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </motion.div>
              </Box>

              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
                  gap: 0.75,
                  width: '100%',
                }}
              >
                <MotionButton
                  size="small"
                  onClick={() => handleBookVisit(car)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  startIcon={<FaCalendarAlt style={{ fontSize: '1rem' }} />}
                  disabled={isLoading}
                  sx={{
                    fontSize: '0.9rem',
                    borderRadius: '20px',
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: '#fff',
                    padding: '8px 20px',
                    textTransform: 'none',
                    fontWeight: 600,
                    minWidth: 'auto',
                    boxShadow: '0 2px 4px rgba(33, 150, 243, 0.2)',
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                      boxShadow: '0 4px 8px rgba(33, 150, 243, 0.3)',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: '#e0e0e0',
                      color: '#9e9e9e',
                      boxShadow: 'none',
                    }
                  }}
                >
                  Prenota
                </MotionButton>

                <MotionButton
                  size="small"
                  onClick={() => handleInfoRequest(car)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  startIcon={<FaInfoCircle style={{ fontSize: '1rem' }} />}
                  disabled={isLoading}
                  sx={{
                    fontSize: '0.9rem',
                    borderRadius: '20px',
                    backgroundColor: '#fff',
                    color: '#444',
                    padding: '8px 20px',
                    textTransform: 'none',
                    fontWeight: 600,
                    minWidth: 'auto',
                    border: '2px solid #e0e0e0',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      borderColor: '#bdbdbd',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: '#f5f5f5',
                      color: '#9e9e9e',
                      border: '2px solid #e0e0e0',
                    }
                  }}
                >
                  Info
                </MotionButton>

                <MotionButton
                  size="small"
                  onClick={() => handleDetailsClick(car)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  startIcon={<FaEye style={{ fontSize: '1rem' }} />}
                  sx={{
                    fontSize: '0.9rem',
                    borderRadius: '20px',
                    backgroundColor: '#e8f5e9',
                    color: '#2e7d32',
                    padding: '8px 20px',
                    textTransform: 'none',
                    fontWeight: 600,
                    minWidth: 'auto',
                    border: '2px solid #c8e6c9',
                    boxShadow: '0 2px 4px rgba(46, 125, 50, 0.1)',
                    '&:hover': {
                      backgroundColor: '#c8e6c9',
                      borderColor: '#93cf95',
                      boxShadow: '0 4px 8px rgba(46, 125, 50, 0.2)',
                    }
                  }}
                >
                  Dettagli
                </MotionButton>
              </Box>
            </Box>
          </Card>
        ))}
      </Box>

      <VehicleDialog
        open={open}
        handleClose={handleClose}
        vehicleData={vehicleData}
        vehicleid={selectedVehicleId}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        showFullScreenGallery={showFullScreenGallery}
      />
    </>
  );
};

export default SearchPanelCarousel; 