import { ArrowForwardIos } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect, useRef, useCallback } from "react";
import {
  FaCalendarAlt,
  FaEuroSign,
  FaHeart,
  FaTachometerAlt,
  FaTags,
  FaInfoCircle,
  FaEye,
  FaCheckCircle,
} from "react-icons/fa";
import { fetchVehicleData } from "../../../api/fetchVehicleData";
import "./CustomCarousel.css";
import NavigationDots from "./NavigationDots";
import VehicleDialog from "./VehicleDialog";
import { trackChatEvent } from '../../../utils/analytics';

const card_height = 430;
const card_width = 350;
const card_gap = 20;
const min_container_padding = window.innerWidth < 600 ? 16 : 48;

const getThemeValue = (theme, path, fallback) => {
  return (
    path.split(".").reduce((acc, part) => acc && acc[part], theme) || fallback
  );
};

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString("it-IT", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

const MotionCard = motion(Card);
const MotionIconButton = motion(IconButton);
const MotionButton = motion(Button);

const CustomCarousel = ({ items, whishlist, onWishlistUpdate, onSendMessage  }) => {
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [open, setOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cardsPerView, setCardsPerView] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const theme = useTheme();
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  useEffect(() => {
    const updateCardsPerView = () => {
      if (containerRef.current) {
        const parentWidth = containerRef.current.parentElement.offsetWidth;
        const availableWidth = parentWidth - (2 * min_container_padding);
        
        if (availableWidth < card_width) {
          setContainerWidth(card_width);
          setCardsPerView(1);
        } else {
          const maxCards = Math.floor((availableWidth + card_gap) / (card_width + card_gap));
          const actualCards = Math.min(maxCards, items.length);
          setCardsPerView(actualCards);
          setContainerWidth((card_width + card_gap) * actualCards - card_gap);
        }
      }
    };

    const resizeObserver = new ResizeObserver(updateCardsPerView);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current.parentElement);
    }
    
    updateCardsPerView();
    
    return () => {
      resizeObserver.disconnect();
    };
  }, [items.length]);

  if (items.length === 0) return null;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe) {
      handleNext();
    } else if (isRightSwipe) {
      handlePrev();
    }
  };

  const handlePrev = () => {
    trackChatEvent.carouselNav('prev');
    setDirection(-1);
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - cardsPerView;
      return newIndex < 0 ? Math.max(0, items.length - cardsPerView) : newIndex;
    });
  };

  const handleNext = () => {
    trackChatEvent.carouselNav('next');
    setDirection(1);
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + cardsPerView;
      return newIndex >= items.length ? 0 : newIndex;
    });
  };

  const handleDotClick = (index) => {
    trackChatEvent.carouselNav(`dot-${index}`);
    setDirection(index > currentIndex ? 1 : -1);
    setCurrentIndex(index);
  };

  const handleOpenVehiclePopup = async (index) => {
    const vehicle = items[index];
    trackChatEvent.viewVehicleDetails(vehicle.vehicleid, vehicle.title);
    setOpen(true);
    setIsLoading(true);
    setVehicleData(null);
    
    try {
      const data = await fetchVehicleData(items[index].vehicleid);
      setVehicleData(data);
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBookVisit = (item) => {
    trackChatEvent.bookVisit(item.vehicleid, item.title);
    onSendMessage?.(`Vorrei prenotare una visita per: ${item.title}`);
  };

  const handleOpenPage = (item) => {
    trackChatEvent.openVehiclePage(item.vehicleid, item.title);
    window.open(`${item.url}?cm=mia`, '_blank');
  };

  return (
    <>
      <Box
        ref={containerRef}
        className="carousel-container"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          overflow: "visible",
          marginBottom: "24px",
          width: "100%",
          minWidth: {
            xs: card_width,
            sm: card_width + (2 * min_container_padding)
          },
          touchAction: "pan-y pinch-zoom",
        }}
      >
        <div
          style={{
            width: containerWidth,
            height: card_height,
            position: "relative",
            margin: "0 auto",
          }}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={currentIndex}
              custom={direction}
              initial="incoming"
              animate="active"
              exit="exit"
              transition={{
                duration: window.innerWidth < 600 ? 0.5 : 0.8,
                ease: [0.4, 0, 0.2, 1],
              }}
              className="carousel"
              variants={{
                incoming: (direction) => ({
                  x: direction > 0 ? containerWidth : -containerWidth,
                  opacity: 0,
                  scale: 0.95,
                }),
                active: { 
                  x: 0, 
                  scale: 1, 
                  opacity: 1,
                  transition: {
                    duration: 0.6,
                    ease: [0.4, 0, 0.2, 1],
                  }
                },
                exit: (direction) => ({
                  x: direction > 0 ? -containerWidth : containerWidth,
                  opacity: 0,
                  scale: 0.95,
                }),
              }}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                display: "flex",
                gap: `${card_gap}px`,
                width: containerWidth,
              }}
            >
              {items.slice(currentIndex, currentIndex + cardsPerView).map((item, index) => {
                const isWishlisted = whishlist?.some(
                  ({ vehicleid }) => vehicleid === item.vehicleid
                );

                return (
                  <MotionCard
                    key={`${item.vehicleid}-${index}`}
                    className="custom-card"
                    whileHover={{ 
                      y: -5,
                      transition: { duration: 0.2 }
                    }}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.3,
                      delay: index * 0.1,
                    }}
                    sx={{
                      width: card_width,
                      height: card_height,
                      flexShrink: 0,
                      margin: 0,
                      boxShadow: 4,
                      borderRadius: "15px",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      backgroundColor: "#fff",
                      color: "#666666",
                      borderBottomLeftRadius: "20px",
                      borderBottomRightRadius: "20px",
                      transition: "all 0.3s ease-in-out",
                      "&:hover": {
                        boxShadow: 10,
                      }
                    }}
                  >
                    <Box sx={{ position: "relative", overflow: "hidden" }}>
                      <CardMedia
                        component="img"
                        height="180"
                        src={item.image}
                        sx={{ 
                          objectFit: "cover", 
                          height: 180,
                          backgroundColor: 'transparent',
                          transition: "transform 0.3s ease-in-out",
                          "&:hover": {
                            transform: "scale(1.05)",
                          },
                          '&.MuiCardMedia-img': {
                            objectFit: 'cover',
                          }
                        }}
                        onError={(e) => {
                          const fallbackLogo = localStorage.getItem("logo") || `${process.env.REACT_APP_DOMAIN}/media/company_logos/3_ms-icon-144x144.png`;
                          e.target.src = fallbackLogo;
                          e.target.style.objectFit = 'contain';
                          e.target.style.padding = '20px';
                          e.target.style.backgroundColor = '#f5f5f5';
                        }}
                      />
                    </Box>

                    {item.badge && (
                      <Chip
                        label={item.badge}
                        className="card-chip"
                        sx={{
                          position: "absolute",
                          top: 8,
                          left: 8,
                          zIndex: 1,
                          fontSize: "1rem",
                          height: "32px",
                          borderRadius: "8px",
                          fontWeight: 600,
                          backgroundColor: theme.palette.v2.primary,
                          color: theme.palette.v2.textPrimary,
                        }}
                      />
                    )}
                    {!item.available && (
                      <Chip
                        label="Non disponibile"
                        className="card-chip"
                        sx={{
                          position: "absolute",
                          top: item.badge ? 40 : 8,
                          left: 8,
                          zIndex: 1,
                          backgroundColor: theme.palette.error.main,
                          color: "white",
                          fontSize: "1rem",
                          height: "32px",
                          borderRadius: "8px",
                          fontWeight: 600,
                        }}
                      />
                    )}

                    <CardContent
                      sx={{
                        padding: "8px",
                        flexGrow: 1,
                        position: "relative",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                          fontWeight: 800,
                          fontSize: {
                            xs: "1rem",
                            sm: "1.3rem",
                          },
                          lineHeight: 1.2,
                          marginBottom: "8px",
                          color: "#000",
                          background: "linear-gradient(135deg, rgba(224, 247, 250,0.2), rgba(224, 247, 250,0))",
                          display: "inline-block",
                          borderRadius: "4px",
                          paddingX: "4px",
                        }}
                      >
                        {item.title}
                      </Typography>

                      <Box
                        sx={{
                          backgroundColor: "rgba(0, 0, 0, 0.05)",
                          borderRadius: "8px",
                          padding: "8px",
                          marginBottom: "8px",
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 2,
                            alignItems: "center",
                          }}
                        >
                          {(() => {
                            const badgeLower = (item.badge || "").toLowerCase();
                            const isMileageAllowed =
                              badgeLower !== "nuovo" &&
                              badgeLower !== "km zero" &&
                              badgeLower !== "km0" &&
                              badgeLower !== "km 0";

                            if (item.description1 && isMileageAllowed) {
                              return (
                                <Box
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                >
                                  <FaTachometerAlt style={{ fontSize: "1rem" }} />
                                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    {formatPrice(item.description1)} km
                                  </Typography>
                                </Box>
                              );
                            } else {
                              return null;
                            }
                          })()}

                          {item.description3 && (
                            <Box
                              sx={{
                                display: "inline-flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <FaCalendarAlt style={{ fontSize: "1rem" }} />
                              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                {item.description3}
                              </Typography>
                            </Box>
                          )}
                        </Box>

                        {item.description2 && (
                          <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.4 }}
                            style={{ display: "flex", flexDirection: "row", gap: "8px", flexWrap: "wrap" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "6px",
                                backgroundColor: "rgba(224, 247, 250, 0.5)",
                                borderRadius: "6px",
                                padding: "6px 8px",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 700,
                                  display: "inline-flex",
                                  alignItems: "center",
                                  margin: 0,
                                }}
                              >
                                <FaEuroSign style={{ marginRight: "4px", fontSize: "1.1rem" }} />
                                {formatPrice(item.description2)}
                              </Typography>

                              {(() => {
                                const oldPrice = parseFloat(item.description4 || "0");
                                const newPrice = parseFloat(item.description2);
                                const hasDiscount = oldPrice > newPrice && oldPrice > 0;
                                if (!hasDiscount) return null;

                                const difference = oldPrice - newPrice;
                                const discountPercentage = Math.round((1 - newPrice / oldPrice) * 100);

                                return (
                                  <>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        textDecoration: "line-through",
                                        color: "#777",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        gap: "2px",
                                      }}
                                    >
                                      <FaEuroSign style={{ fontSize: "0.8rem" }} />
                                      {formatPrice(oldPrice)}
                                    </Typography>
                                    <Chip
                                      label={`-${discountPercentage}%`}
                                      size="small"
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "0.75rem",
                                        backgroundColor: "#03A9F4",
                                        color: "#fff",
                                      }}
                                    />
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: 600,
                                        color: "success.main",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        gap: "4px",
                                      }}
                                    >
                                      <FaCheckCircle style={{ fontSize: "1rem" }} />
                                      Risparmi {formatPrice(difference)} €
                                    </Typography>
                                  </>
                                );
                              })()}
                            </Box>
                          </motion.div>
                        )}
                      </Box>

                      {item.promotion && (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.95 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ delay: 0.3 }}
                        >
                          <Box
                            sx={{
                              mt: 1,
                              p: 1,
                              background: `linear-gradient(135deg, rgb(224, 195, 252) 30%, rgb(142, 197, 252) 90%)`,
                              borderRadius: "8px",
                              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                              transition: "transform 0.2s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.02)",
                              },
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "bold",
                                fontSize: "0.9rem",
                              }}
                            >
                              <FaTags
                                style={{
                                  marginRight: "4px",
                                  fontSize: "0.8rem",
                                  color: "#6A0572",
                                }}
                              />{" "}
                              Scopri la promozione!
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 400,
                                marginTop: "4px",
                                fontSize: "0.8rem",
                              }}
                            >
                              {item.descrizione_economica_promozione_ai}
                            </Typography>
                            <MotionButton
                              size="small"
                              href={
                                item.link_promozione_promozione_ai !== "NULL"
                                  ? item.link_promozione_promozione_ai
                                  : "#"
                              }
                              target="_blank"
                              variant="contained"
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              sx={{
                                mt: 1,
                                borderRadius: "20px",
                                boxShadow: 1,
                                background: theme.palette.primary.main,
                                fontSize: "0.7rem",
                                padding: "4px 8px",
                              }}
                            >
                              Scopri di più
                            </MotionButton>
                          </Box>
                        </motion.div>
                      )}
                    </CardContent>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        p: 1,
                        gap: 1.5,
                      }}
                    >
                      {item.available ? (
                        <>
                          <MotionButton
                            size="small"
                            onClick={() => handleBookVisit(item)}
                            variant="contained"
                            whileHover={{ scale: 1.03, y: -2 }}
                            whileTap={{ scale: 0.98 }}
                            startIcon={<FaCalendarAlt style={{ fontSize: '1rem' }} />}
                            sx={{
                              fontSize: '1rem',
                              borderRadius: '20px',
                              boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
                              background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                              color: 'white',
                              padding: '6px 12px',
                              textTransform: 'none',
                              fontWeight: 600,
                              '&:hover': {
                                boxShadow: '0 6px 20px rgba(0,0,0,0.3)',
                              },
                            }}
                          >
                            Prenota
                          </MotionButton>

                          <MotionButton
                            size="small"
                            onClick={() => onSendMessage?.(`Vorrei più informazioni su: ${item.title}, veicolo ${item.vehicleid}`)}
                            variant="outlined"
                            whileHover={{ scale: 1.03, y: -2 }}
                            whileTap={{ scale: 0.98 }}
                            startIcon={<FaInfoCircle style={{ fontSize: '1rem' }} />}
                            sx={{
                              fontSize: '1rem',
                              borderRadius: '20px',
                              color: theme.palette.v2.primary,
                              borderColor: theme.palette.v2.primary,
                              borderWidth: 2,
                              fontWeight: 600,
                              padding: '6px 12px',
                              textTransform: 'none',
                              '&:hover': {
                                boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
                                borderColor: theme.palette.v2.primary,
                              },
                            }}
                          >
                            Info
                          </MotionButton>

                          <MotionButton
                            size="small"
                            onClick={() => handleOpenVehiclePopup(currentIndex + index)}
                            variant="outlined"
                            whileHover={{ scale: 1.05, y: -2 }}
                            whileTap={{ scale: 0.95 }}
                            startIcon={<FaEye style={{ fontSize: '1rem' }} />}
                            sx={{
                              fontSize: '1rem',
                              borderRadius: '20px',
                              color: theme.palette.v2.primary,
                              borderColor: theme.palette.v2.primary,
                              borderWidth: 2,
                              fontWeight: 600,
                              padding: '6px 12px',
                              textTransform: 'none',
                              '&:hover': {
                                boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
                                borderColor: theme.palette.v2.primary,
                              },
                            }}
                          >
                            Dettagli
                          </MotionButton>
                        </>
                      ) : null}
                    </Box>
                  </MotionCard>
                );
              })}
            </motion.div>
          </AnimatePresence>

          {items.length > cardsPerView && (
            <>
              <MotionIconButton
                className="carousel-arrow prev-arrow"
                onClick={handlePrev}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                aria-label="Previous"
                sx={{
                  position: "absolute",
                  left: { xs: 8, sm: -48 },
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                  backgroundColor: "rgba(0, 0, 0, 0.5)!important",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)!important",
                  },
                  borderRadius: "50%",
                  width: { xs: "32px", sm: "40px" },
                  height: { xs: "32px", sm: "40px" },
                  cursor: "pointer",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <motion.svg
                  focusable="false"
                  aria-hidden="true"
                  viewBox="-4 0 24 24"
                  width="24"
                  height="24"
                  whileHover={{ scale: 1.1 }}
                >
                  <path
                    d="M11.67 3.87 9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"
                    style={{ fill: "white" }}
                  />
                </motion.svg>
              </MotionIconButton>
              <MotionIconButton
                className="carousel-arrow next-arrow"
                onClick={handleNext}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                aria-label="Next"
                sx={{
                  position: "absolute",
                  right: { xs: 8, sm: -48 },
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                  backgroundColor: "rgba(0, 0, 0, 0.5)!important",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)!important",
                  },
                  borderRadius: "50%",
                  width: { xs: "32px", sm: "40px" },
                  height: { xs: "32px", sm: "40px" },
                  cursor: "pointer",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <motion.div whileHover={{ scale: 1.1 }}>
                  <ArrowForwardIos
                    sx={{
                      color: "white",
                      fontSize: "24px",
                    }}
                  />
                </motion.div>
              </MotionIconButton>
            </>
          )}
        </div>
        <div style={{ height: 50 }}>
          <NavigationDots
            items={items}
            currentIndex={currentIndex}
            handleDotClick={handleDotClick}
            cardsPerView={cardsPerView}
          />
        </div>
      </Box>

      <VehicleDialog
        open={open}
        handleClose={handleClose}
        vehicleData={vehicleData}
        vehicleid={items[currentIndex].vehicleid}
        isLoading={isLoading}
      />
    </>
  );
};

export default CustomCarousel;