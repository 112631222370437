import {
  Box,
  Fade,
  Grow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState, useMemo, useRef } from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import styled from 'styled-components';
import { useTheme as useMuiTheme } from '@mui/material/styles';

import ChatHeader from "./ChatHeader";
import ToggleChat from "./ToggleChat";
import ChatBody from "./ChatBody/index.jsx";
import ChatFooter from "./ChatFooter";
import ConnectionStatus from "./ConnectionStatus";
import { useWebSocketContext } from "../context/Theme";
import CustomScrollbar from './CustomScrollbar';
import SearchPanel from "./SearchPanel";
import MobileChatFooter from "./MobileChatFooter";

/**
 * Splits out any messages that appear to be "car-search" related.
 * Only do this when NOT on mobile. Otherwise keep them all in chat.
 *
 * If a message has both text + carouselItems, we split it into:
 *   1) A text-only message for the main chat
 *   2) A carousel-only message for the SearchPanel
 */
function partitionMessages(messages, isMobile) {
  if (isMobile) {
    // No partition for mobile. All messages go to chat.
    return { 
      chatMessages: messages, 
      searchResults: [] 
    };
  }

  const chatMessages = [];
  const searchResults = [];

  messages.forEach((msg, idx) => {
    const isCarSearchTool = Array.isArray(msg.tool)
      ? msg.tool.some((t) => t.includes("car_stock_search"))
      : typeof msg.tool === "string" && msg.tool.includes("car_stock_search");

    const hasCarousel = msg.carouselItems && msg.carouselItems.length > 0;

    // Only consider it a search result if it has actual carousel items
    if (hasCarousel) {
      if (msg.text) {
        // (A) Chat receives a text-only copy
        chatMessages.push({ 
          ...msg, 
          carouselItems: null,
          originalIndex: idx
        });
      }
      
      // (B) Search results get a carousel-only copy
      searchResults.push({ 
        ...msg, 
        text: null,
        originalIndex: idx
      });
    } else {
      // All other messages go to chat
      chatMessages.push(msg);
    }
  });

  return { chatMessages, searchResults };
}

// Update the CollapseHandle styled component
const CollapseHandle = styled(motion.div)`
  position: fixed;
  right: ${props => props.isOpen ? "calc(min(800px, 95vw) + 2px)" : "calc(min(500px, 90vw) + 2px)"};
  bottom: 45vh;
  background: ${props => props.$bgcolor};
  padding: 12px 4px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;
  transition: right 0.3s ease;

  &:hover {
    background: ${props => props.$bghover};
  }
`;

// Update the styling and animation for the chat and search panel container
const ChatAndSearchContainer = styled(motion.div)`
  display: flex;
  position: fixed;
  bottom: ${props => props.isMobile ? 0 : '2vh'};
  right: ${props => props.isMobile ? 0 : '2vw'};
  height: ${props => props.isMobile ? '100%' : '90vh'};
  top: ${props => props.isMobile ? 0 : 'auto'};
  width: ${props => props.isMobile ? '100vw' : 'auto'};
  perspective: 2000px;
  z-index: 1000;
  justify-content: ${props => props.isMobile ? 'center' : 'flex-start'};
  flex-direction: row-reverse;
`;

const ChatContainer = styled(motion.div)`
  width: ${props => props.isMobile ? '100%' : 'min(500px, 90vw)'};
  height: 100%;
  position: relative;
  z-index: 2;
  flex-shrink: 0;

  ${props => props.isMobile && `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    margin-top: 0;
  `}

  // Add a subtle background gradient for depth
  background: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8));
`;

const SearchPanelContainer = styled(motion.div)`
  position: relative;
  height: 100%;
  transform-origin: right center;
  z-index: 1;
  display: ${props => props.isMobile ? 'none' : 'block'};
  flex-shrink: 0;
`;

const ChatWrapper = () => {
  const {
    isAuthenticated,
    messages,
    isLoading,
    setIsLoading,
    wishlistItems,
    error,
    isReconnecting,
    reconnectCountdown,
    reconnectAttempts,
    MAX_RECONNECT_ATTEMPTS,
    handleManualReconnect,
    handleSend,
    handleResendLastMessage,
    handleWishlistUpdate,
    isHumanControlled,
    humanControlMessage,
    isConnected,
    accessToken,
    isChatVisible,
    toggleChat,
    notification,
    activeTrigger,
  } = useWebSocketContext();

  const [isChatWide, setChatWide] = useState(false);
  const [searchPanelOpen, setSearchPanelOpen] = useState(false);
  const [showReconnectionUI, setShowReconnectionUI] = useState(false);
  const [inputValue, setInputValue] = useState("");
  
  // We track the "active" search index => defaults to the LAST search result
  const [currentSearchIndex, setCurrentSearchIndex] = useState(0);
  const [lastSearchResultsLength, setLastSearchResultsLength] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // We'll create a ref to ChatBody so we can scroll to a particular message
  const chatBodyRef = useRef(null);

  // Add a ref to track the last known carousel content
  const lastCarouselContentRef = useRef(null);

  const toggleChatWidth = () => setChatWide(!isChatWide);

  const toggleSearchPanel = () => {
    if (!isMobile) {
      setSearchPanelOpen((open) => !open);
    }
  };

  useEffect(() => {
    if (isMobile && isChatVisible) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [isMobile, isChatVisible]);

  useEffect(() => {
    let timer;
    if (!isConnected) {
      timer = setTimeout(() => {
        setShowReconnectionUI(true);
      }, 3000);
    } else {
      setShowReconnectionUI(false);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isConnected]);

  useEffect(() => {
    if (isHumanControlled && isLoading) {
      setIsLoading(false);
    }
  }, [isHumanControlled, isLoading, setIsLoading]);

  useEffect(() => {
    // Close search panel when chat is closed
    if (!isChatVisible) {
      setSearchPanelOpen(false);
    }
  }, [isChatVisible]);

  const handleToggleChat = () => {
    if (!isChatVisible) {
      setSearchPanelOpen(false);
    }
    toggleChat();
  };

  /**
   * Partition messages for desktop:
   * "chatMessages" => normal text chat
   * "searchResults" => carousels from `car_stock_search`
   * For mobile, keep them all in the chat.
   */
  const { chatMessages, searchResults } = useMemo(
    () => partitionMessages(messages, isMobile),
    [messages, isMobile]
  );

  /**
   * Watch for changes in searchResults and only open/update panel
   * if there are actually new carousel items
   */
  useEffect(() => {
    if (!isMobile && searchResults.length > 0) {
      // Get the latest search result's carousel items
      const latestCarouselItems = searchResults[searchResults.length - 1]?.carouselItems;
      
      // Create a content signature for comparison
      const currentContent = latestCarouselItems ? JSON.stringify(latestCarouselItems) : null;
      
      // Only update if we have new carousel content
      if (currentContent && currentContent !== lastCarouselContentRef.current) {
        setSearchPanelOpen(true);
        // Only update currentSearchIndex if we have new content
        setCurrentSearchIndex(searchResults.length - 1);
        lastCarouselContentRef.current = currentContent;
      }
    }
  }, [searchResults, isMobile]);

  // Update handleNavigateSearch to be more strict about boundaries
  const handleNavigateSearch = (delta) => {
    setCurrentSearchIndex((prev) => {
      const newIndex = prev + delta;
      // Only allow navigation within valid search results
      if (newIndex < 0 || newIndex >= searchResults.length) {
        return prev; // Keep current index if out of bounds
      }
      return newIndex;
    });
  };

  /**
   * Whenever currentSearchIndex changes,
   * we can scroll the chat to the corresponding original message if we want
   */
  useEffect(() => {
    const target = searchResults[currentSearchIndex];
    if (target && chatBodyRef.current && typeof chatBodyRef.current.scrollToMessage === "function") {
      chatBodyRef.current.scrollToMessage(target.originalIndex);
    }
  }, [currentSearchIndex, searchResults]);

  // Watch for changes in searchResults
  useEffect(() => {
    if (!isMobile && searchResults.length > lastSearchResultsLength) {
      setSearchPanelOpen(true);
      setLastSearchResultsLength(searchResults.length);
    }
  }, [searchResults, isMobile, lastSearchResultsLength]);

  // Get the latest predicted questions
  const latestPredictedQuestions = useMemo(() => {
    const lastPredictedMsg = messages
      .filter(m => m.type === "predicted_questions")
      .pop();
    return lastPredictedMsg?.questions || [];
  }, [messages]);

  const handleSearchOptionClick = () => {
    if (!isMobile) {
      console.log("Toggling search panel");
      setSearchPanelOpen(true); // Force open the panel
    }
  };

  return (
    <>
      <AnimatePresence>
        {isChatVisible && (
          <ChatAndSearchContainer
            isMobile={isMobile}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
          >
            <ChatContainer isMobile={isMobile}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                  borderRadius: isMobile ? 0 : "20px",
                  boxShadow: isMobile ? "none" : "0 8px 24px rgba(0, 0, 0, 0.08)",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  position: "relative",
                  border: isMobile ? "none" : "1px solid",
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    zIndex: 1001,
                    backgroundColor: theme.palette.v2.chatBackground,
                    userSelect: "none",
                    cursor: "default",
                    ...(isMobile && {
                      position: 'sticky',
                      top: 0,
                    })
                  }}
                >
                  <ChatHeader
                    isMobile={isMobile}
                    toggleChat={handleToggleChat}
                    toggleChatWidth={toggleChatWidth}
                    isChatWide={isChatWide}
                    isLoading={isLoading}
                    onSendMessage={(message) => handleSend(message)}
                    isHumanControlled={isHumanControlled}
                    humanControlMessage={humanControlMessage}
                    isOnline={isConnected}
                    toggleSearchPanel={toggleSearchPanel}
                  />
                </Box>

                <Box
                  sx={{
                    position: "relative",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    overflow: "hidden",
                    paddingTop: isMobile ? "0" : "64px",
                    marginTop: 0,
                  }}
                >
                  <CustomScrollbar>
                    <Fade in={isConnected} timeout={500}>
                      <Box
                        style={{
                          display: isConnected ? "block" : "none",
                          ...(isMobile && {
                            touchAction: 'pan-y',
                            WebkitOverflowScrolling: 'touch',
                          }),
                        }}
                      >
                        <ChatBody
                          ref={chatBodyRef}
                          messages={chatMessages.filter(
                            (m) => !m.type || m.type !== "predicted_questions"
                          )}
                          isLoading={isLoading}
                          onSendMessage={handleSend}
                          onSendFile={(file, message) => handleSend(message, file)}
                          onSendPersonalData={(personaldata) =>
                            handleSend(
                              "Dati personali salvati",
                              null,
                              null,
                              personaldata
                            )
                          }
                          isConnected={isConnected}
                          isAuthenticated={isAuthenticated}
                          isHumanControlled={isHumanControlled}
                          setIsLoading={setIsLoading}
                          accessToken={accessToken}
                          activeTrigger={activeTrigger}
                          onSearchOptionClick={handleSearchOptionClick}
                          isMobile={isMobile}
                        />
                      </Box>
                    </Fade>

                    <Fade in={!isConnected && showReconnectionUI} timeout={500}>
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(255, 255, 255, 0.95)",
                          backdropFilter: "blur(5px)",
                        }}
                      >
                        <Grow in={!isConnected && showReconnectionUI} timeout={800}>
                          <Typography
                            variant="h5"
                            sx={{
                              mb: 2,
                              fontWeight: "bold",
                              color: theme.palette.primary.main,
                            }}
                          >
                            {isReconnecting
                              ? "Riconnessione in corso..."
                              : "Connessione persa"}
                          </Typography>
                        </Grow>
                        <ConnectionStatus
                          isConnected={isConnected}
                          isReconnecting={isReconnecting}
                          reconnectCountdown={reconnectCountdown}
                          reconnectAttempts={reconnectAttempts}
                          MAX_RECONNECT_ATTEMPTS={MAX_RECONNECT_ATTEMPTS}
                          onManualReconnect={handleManualReconnect}
                        />
                      </Box>
                    </Fade>
                  </CustomScrollbar>
                </Box>

                <Box
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    zIndex: 10,
                    backgroundColor: theme.palette.v2.chatBackground,
                    userSelect: "none",
                    cursor: "default",
                  }}
                >
                  <Fade in={isConnected} timeout={500}>
                    <div style={{ display: isConnected ? "block" : "none" }}>
                      {isMobile ? (
                        <MobileChatFooter
                          onSendMessage={handleSend}
                          isLoading={isLoading}
                          isHumanControlActive={isHumanControlled}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          predictedQuestions={latestPredictedQuestions}
                        />
                      ) : (
                        <ChatFooter
                          onSendMessage={handleSend}
                          onSendFile={(file, message) => handleSend(message, file)}
                          isLoading={isLoading}
                          isHumanControlActive={isHumanControlled}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          predictedQuestions={latestPredictedQuestions}
                          onQuestionClick={(question) => {
                            setInputValue(question);
                            handleSend(question);
                          }}
                          isMobile={isMobile}
                        />
                      )}
                    </div>
                  </Fade>
                </Box>
              </Box>
            </ChatContainer>

            <AnimatePresence>
              {searchPanelOpen && !isMobile && (
                <SearchPanelContainer
                  initial={{ opacity: 0, rotateY: -90, x: 50 }}
                  animate={{ 
                    opacity: 1, 
                    rotateY: 0,
                    x: 0,
                    transition: { 
                      duration: 0.5, 
                      ease: "easeInOut",
                    }
                  }}
                  exit={{ 
                    opacity: 0, 
                    rotateY: -90,
                    x: 50,
                    transition: { 
                      duration: 0.5, 
                      ease: "easeInOut" 
                    }
                  }}
                >
                  <SearchPanel
                    searchMessages={
                      searchResults.length > 0
                        ? [searchResults[currentSearchIndex]]
                        : []
                    }
                    onNavigateSearch={handleNavigateSearch}
                    currentIndex={currentSearchIndex}
                    totalCount={searchResults.length}
                    onClose={() => setSearchPanelOpen(false)}
                    isLoading={isLoading}
                    isHumanControlled={isHumanControlled}
                  />
                </SearchPanelContainer>
              )}
            </AnimatePresence>
          </ChatAndSearchContainer>
        )}
      </AnimatePresence>
      {!isChatVisible && (
        <ToggleChat 
          isChatVisible={isChatVisible} 
          toggleChat={handleToggleChat}
          onSendMessage={(message) => handleSend(message)}
          notification={notification}
        />
      )}
    </>
  );
};

export default ChatWrapper;