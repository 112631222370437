import React, { useState } from 'react';
import { MessageCircle, Search, ChevronRight, X, ChevronUp, UserCircle2 } from "lucide-react";
import { AnimatePresence, motion } from "framer-motion";
import styled, { keyframes, css } from "styled-components";
import { IconButton, Tooltip } from "@mui/material";
import { StyledComponentsThemWrapper } from "./StyledComponentsThemWrapper";
import { trackChatEvent } from '../utils/analytics';

const float = keyframes`
  0%, 100% { 
    transform: translateY(0px); 
  }
  50% { 
    transform: translateY(-3px); 
  }
`;

const pulse = keyframes`
  0% { 
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.4);
    transform: scale(1);
  }
  70% { 
    box-shadow: 0 0 0 10px rgba(34, 197, 94, 0);
    transform: scale(1.05);
  }
  100% { 
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 0);
    transform: scale(1);
  }
`;

const shine = keyframes`
  0% { 
    background-position: -200% 50%; 
  }
  100% { 
    background-position: 200% 50%; 
  }
`;

const glow = keyframes`
  0% { 
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.7);
  }
  70% { 
    box-shadow: 0 0 0 6px rgba(239, 68, 68, 0);
  }
  100% { 
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0);
  }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-4px);
  }
`;

const wave = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

const morphing = keyframes`
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    transform: rotate(0deg);
  }
  33% {
    border-radius: 70% 30% 50% 50%/30% 30% 70% 70%;
    transform: rotate(1deg);
  }
  66% {
    border-radius: 40% 60% 60% 40%/50% 60% 40% 50%;
    transform: rotate(-1deg);
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    transform: rotate(0deg);
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
`;

const glowPulse = keyframes`
  0% {
    box-shadow: 
      0 8px 32px ${props => `${props.theme.palette.action.shadowLight}`},
      0 0 0 0 ${props => `${props.theme.palette.primary.main}40`};
  }
  70% {
    box-shadow: 
      0 8px 32px ${props => `${props.theme.palette.action.shadowLight}`},
      0 0 0 20px ${props => `${props.theme.palette.primary.main}00`};
  }
  100% {
    box-shadow: 
      0 8px 32px ${props => `${props.theme.palette.action.shadowLight}`},
      0 0 0 0 ${props => `${props.theme.palette.primary.main}00`};
  }
`;

const gradientShift = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const autoRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

const pulseCircle = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.85);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const ToggleContainer = styled(motion.div)`
  position: fixed;
  bottom: clamp(20px, 4dvh, 40px);
  right: clamp(20px, 4dvw, 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
  width: clamp(250px, 35dvw, 400px);
  max-height: calc(100dvh - 40px);

  @media (max-width: 640px) {
    bottom: 80px;
    right: 20px;
    width: auto;
    max-width: 300px;
    transform-origin: bottom right;
    z-index: 998;
  }
`;

const SearchIconStyled = styled(Search)`
  color: ${props => props.theme.palette.v2.textPrimary};
  opacity: 0.8;
  min-width: 24px;
  width: 24px;
  height: 24px;

  @media (max-width: 640px) {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }
`;

const SearchBarContainer = styled(motion.div)`
  background: ${props => props.theme.palette.v2.primary};
  padding: 16px 20px;
  border-radius: 20px;
  margin-bottom: 8px;
  box-shadow: 
    0 10px 40px -10px ${props => `${props.theme.palette.action.shadowLight}`},
    0 0 20px -5px ${props => `${props.theme.palette.action.shadow}`};
  position: relative;
  cursor: text;
  display: flex;
  align-items: center;
  backdrop-filter: blur(10px);
  border: 1px solid ${props => props.theme.palette.divider};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  background-image: linear-gradient(
    to right,
    ${props => props.theme.palette.primary.light},
    ${props => props.theme.palette.primary.main},
    ${props => props.theme.palette.primary.light}
  );
  background-size: 200% auto;
  ${css`animation: ${shine} 4s linear infinite;`}

  &:hover {
    transform: translateY(-3px);
    box-shadow: 
      0 25px 65px -15px ${props => `${props.theme.palette.action.shadow}`},
      0 0 35px -5px ${props => `${props.theme.palette.action.shadowLight}`};
    border-color: ${props => props.theme.palette.primary.main}60;
  }

  &:hover ${SearchIconStyled} {
    color: ${props => props.theme.palette.primary.main};
    opacity: 1;
  }

  @media (max-width: 640px) {
    display: none;
  }
`;

const SearchInput = styled.input`
  border: none;
  border-radius: 25px;
  outline: none;
  background: transparent;
  color: ${props => props.theme.palette.v2.textPrimary};
  font-size: 16px;
  flex: 1;
  margin-left: 8px;
  padding: 8px 16px;
  width: 100%;
  transition: all 0.2s ease;

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    color: transparent;
  }

  @media (max-width: 640px) {
    font-size: 16px;
    padding: 8px 12px;
    margin-left: 8px;
  }
`;

const SearchContainer = styled.div`
  position: relative;
  flex: 1;
`;

const PlaceholderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.theme.palette.v2.textPrimary};
  opacity: 0.8;
  pointer-events: none;
  display: flex;
  align-items: center;
  ${props => props.isHidden && 'display: none;'};
  white-space: nowrap;

  @media (max-width: 640px) {
    font-size: 14px;
    transform: translateY(-50%) scale(0.9);
    transform-origin: left center;
  }
`;

const MiaLogo = styled.img.attrs({
  src: '/Mia_Logo_white.png',
  alt: 'MIA',
  onError: (e) => {
    e.target.onerror = null;
    e.target.src = 'https://www.aitomotivelab.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLOGO_MIA.731a2d9a.png&w=1200&q=75';
  }
})`
  width: 600%;
  height: 600%;
  object-fit: contain;
  animation-name: ${autoRotate};
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-delay: 20s;
  animation-iteration-count: infinite;
  animation-play-state: running;

  &:hover {
    animation-play-state: paused;
  }

  @media (max-width: 640px) {
    width: 360%;
    height: 360%;
  }
`;

const StyledIconButton = styled(IconButton)`
  background: linear-gradient(
    -45deg,
    ${props => props.theme.palette.primary.dark},
    ${props => props.theme.palette.primary.main},
    ${props => props.theme.palette.primary.light},
    ${props => props.theme.palette.primary.main}
  ) !important;
  background-size: 300% 300% !important;
  animation: 
    ${gradientShift} 5s ease infinite,
    ${glowPulse} 3s infinite,
    ${pulseCircle} 3s ease-in-out infinite !important;
  color: ${props => props.theme.palette.primary.contrastText} !important;

  width: 75px !important;
  height: 75px !important;
  border-radius: 50% !important;

  box-shadow: 
    0 8px 32px ${props => `${props.theme.palette.action.shadowLight}`},
    0 0 0 1px ${props => `${props.theme.palette.primary.main}30`} !important;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) !important;
  position: relative;
  overflow: visible;
  
  &:hover {
    transform: translateY(-4px) !important;
    box-shadow: 
      0 12px 48px ${props => `${props.theme.palette.action.shadowLight}`},
      0 0 0 2px ${props => props.theme.palette.primary.main} !important;
    animation: ${gradientShift} 3s ease infinite !important;
  }

  &:active {
    transform: translateY(0) !important;
  }

  @media (max-width: 640px) {
    width: 65px !important;
    height: 65px !important;
  }
`;

const ChatIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 100%;
    height: 100%;
    padding: 12px;
    color: ${props => props.theme.palette.background.paper};
    opacity: 0.15;
  }
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 2px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    animation: ${autoRotate} 0.6s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    animation-iteration-count: 1;
    animation-play-state: paused;
    animation-delay: 20s;
    animation-iteration-count: infinite;
  }

  ${StyledIconButton}:hover & img {
    transform: rotate(180deg);
    animation-play-state: paused;
  }

  @media (max-width: 640px) {
    width: 32px;
    height: 32px;
    padding: 1px;
  }
`;

const OnlineBadge = styled.div`
  position: absolute;
  bottom: -10px;
  right: 2px;
  background: linear-gradient(135deg, #22c55e, #16a34a);
  color: white;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 10px;
  border: 2px solid ${props => props.theme.palette.background.paper};
  display: flex;
  align-items: center;
  gap: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 2;
  transform-origin: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    animation: ${pulse} 2s infinite;
    box-shadow: 0 0 10px #fff;
  }

  ${StyledIconButton}:hover & {
    transform: scale(1.1) translateX(-2px);
  }

  @media (max-width: 640px) {
    font-size: 8px;
    padding: 2px 4px;
    
    &::before {
      width: 3px;
      height: 3px;
    }
  }
`;

const SubFormContainer = styled(motion.div)`
  background: ${props => props.theme.palette.background.paper};
  border-radius: 24px;
  padding: 24px;
  margin-bottom: 12px;
  border: 1px solid ${props => props.theme.palette.divider};
  width: 100%;
  box-shadow: 0 10px 40px -10px ${props => `${props.theme.palette.action.shadowLight}`};
  backdrop-filter: blur(10px);

  @media (max-width: 640px) {
    position: fixed;
    bottom: 140px;
    right: 20px;
    width: 300px;
    padding: 20px;
    border-radius: 20px;
    z-index: 997;
  }
`;

const FormTitle = styled.h3`
  margin: 0 0 20px 0;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.palette.primary.main};
  text-align: center;
  font-family: inherit;

  @media (max-width: 640px) {
    font-size: 16px;
    margin-bottom: 16px;
  }
`;

const FormInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${props => props.theme.palette.divider};
  margin-bottom: 8px;
  background: ${props => props.theme.palette.background.paper};
  color: ${props => props.theme.palette.text.primary};
  font-size: 14px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover, &:focus {
    border-color: ${props => props.theme.palette.primary.main};
    outline: none;
  }
`;

const BrandInput = styled(FormInput)`
  border-radius: 12px;
  margin-bottom: 12px;
  height: 48px;
  padding: 8px 16px;
  font-size: 16px;
  border: 2px solid ${props => props.theme.palette.divider};
  transition: all 0.2s ease;

  &:hover, &:focus {
    border-color: ${props => props.theme.palette.primary.main};
    box-shadow: 0 0 0 4px ${props => `${props.theme.palette.primary.main}15`};
  }

  @media (max-width: 640px) {
    height: 48px;
    font-size: 16px;
    border-radius: 12px;
    padding: 8px 16px;
    margin-bottom: 12px;

    &::placeholder {
      font-size: 16px;
    }
  }
`;

const Select = styled.select`
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  border-radius: 12px;
  border: 2px solid ${props => props.theme.palette.divider};
  margin-bottom: 20px;
  background: ${props => props.theme.palette.background.default};
  color: ${props => props.theme.palette.text.primary};
  font-size: 16px;
  transition: all 0.2s ease;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px;

  &:hover, &:focus {
    border-color: ${props => props.theme.palette.primary.main};
    box-shadow: 0 0 0 4px ${props => `${props.theme.palette.primary.main}15`};
  }

  @media (max-width: 640px) {
    height: 48px;
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 14px;
  background: ${props => props.theme.palette.primary.main};
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 16px;
  font-weight: 600;
  font-family: inherit;
  position: relative;
  overflow: hidden;

  &:hover {
    background: ${props => props.theme.palette.primary.dark};
    transform: translateY(-2px);
    box-shadow: 0 4px 12px ${props => `${props.theme.palette.primary.main}40`};
  }

  &:active {
    transform: translateY(0);
  }

  @media (max-width: 640px) {
    padding: 16px;
    font-size: 16px;
    border-radius: 12px;
  }
`;

const DateTimeInput = styled(FormInput)`
  border-radius: 8px;
  width: calc(100% - 32px);
  margin: 0 16px 8px 16px;
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: ${props => props.theme.palette.mode === 'dark' ? 'invert(1)' : 'none'};
  }

  @media (max-width: 640px) {
    width: 100%;
    margin: 0;
    padding: 12px;
    font-size: 16px;
  }
`;

const TimeSelect = styled(FormInput).attrs({ as: 'select' })`
  border-radius: 100px;
  width: calc(100% - 32px);
  margin: 0 16px 8px 16px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px;
  padding-right: 42px;

  @media (max-width: 640px) {
    width: 100%;
    margin: 0;
    padding: 12px;
    font-size: 16px;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px !important;
  background: transparent !important;
  color: ${props => props.theme.palette.text.secondary} !important;
  
  &:hover {
    background: ${props => props.theme.palette.action.hover} !important;
    color: ${props => props.theme.palette.text.primary} !important;
  }

  @media (max-width: 640px) {
    display: none !important;
  }
`;

const BrandInputContainer = styled.div`
  position: relative;
  margin-bottom: 8px;
  width: 100%;
  margin-right: 0;

  @media (max-width: 640px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const SuggestionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: ${props => props.theme.palette.background.paper};
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: 8px;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  
  @media (max-height: 600px) {
    max-height: 150px;
  }

  @media (max-height: 400px) {
    max-height: 100px;
  }

  @media (max-width: 640px) {
    border-radius: 8px;
    margin-top: 4px;
    max-height: 200px;
    border-width: 1px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

const SuggestionItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.palette.action.hover};
    color: ${props => props.theme.palette.primary.main};
  }

  ${props => props.selected && `
    background: ${props.theme.palette.action.selected};
    color: ${props.theme.palette.primary.main};
  `}

  @media (max-width: 640px) {
    padding: 8px 12px;
    font-size: 14px;
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    min-height: 40px;
    display: flex;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }

    &:active {
      background: ${props => props.theme.palette.action.selected};
    }
  }
`;

const brandSuggestions = [
  // Volkswagen Group Brands
  // Volume Brands
  'Volkswagen', 'Volkswagen Commercial Vehicles', 'VW', 'VW Commercial',
  'ŠKODA', 'SEAT', 'CUPRA',
  
  // Premium/Sport Brands
  'Audi', 'Audi Sport', 'Audi RS', 
  'Porsche', 'Porsche Motorsport',
  'Lamborghini',
  
  // Super Luxury Brands
  'Bentley', 
  'Bugatti', // Until 2021
  
  // Historical VW Group Brands
  'Auto Union', 
  'DKW', 
  'Horch', 
  'NSU', 
  'Wanderer',
  
  // Truck Brands
  'MAN', 
  'Scania', 
  'Navistar International',
  
  // Motorcycle Brand
  'Ducati',

  // Sub-brands and Performance Divisions
  'Audi Sport GmbH',
  'quattro GmbH',
  'Volkswagen R',
  'SEAT Sport',
  'ŠKODA vRS',
  'Porsche Exclusive Manufaktur',

  // Premium/Luxury Brands
  'Mercedes-Benz', 'Mercedes-AMG', 'Mercedes-Maybach', 'Lexus', 
  'Land Rover', 'Range Rover', 'Jaguar', 'Volvo', 'Genesis', 'Infiniti', 'Acura',

  // Italian Brands
  'Alfa Romeo', 'Ferrari', 'Fiat', 'Maserati', 'Lancia', 'Abarth', 
  'Autobianchi', 'De Tomaso', 'Innocenti', 'Bizzarrini', 'Fornasari', 'Ermini',

  // German Brands
  'BMW', 'Opel', 'MINI', 'Alpina', 'Artega', 'Gumpert', 'Isdera', 'Mansory', 
  'Maybach', 'Melkus', 'Ruf', 'Wiesmann', 'Brabus', 'BMW M', 'BMW i',

  // French Brands
  'Peugeot', 'Renault', 'Alpine', 'Citroën', 'DS Automobiles', 'Delage',
  'Facel Vega', 'Gordini', 'Panhard', 'Talbot', 'Venturi', 'De Dion-Bouton',

  // Asian Brands
  // Japanese
  'Toyota', 'Lexus', 'Honda', 'Acura', 'Mazda', 'Nissan', 'NISMO', 'Infiniti', 
  'Suzuki', 'Subaru', 'Mitsubishi', 'Daihatsu', 'Scion', 'Isuzu', 'Hino',
  // Korean
  'Hyundai', 'Kia', 'Genesis', 'SsangYong', 'Daewoo',
  // Chinese
  'BYD', 'Geely', 'Great Wall', 'Haval', 'NIO', 'Xpeng', 'Li Auto', 'Lynk & Co',
  'MG', 'Hongqi', 'Wuling', 'Zeekr', 'Ora', 'Voyah', 'Weltmeister',
  // Other Asian
  'Proton', 'Perodua', 'Tata', 'Mahindra', 'Maruti Suzuki', 'VinFast',

  // American Brands
  'Ford', 'Lincoln', 'Chevrolet', 'GMC', 'Cadillac', 'Jeep', 'Chrysler', 'Dodge', 
  'RAM', 'Tesla', 'Rivian', 'Lucid', 'Fisker', 'Karma', 'Bollinger', 'Canoo',
  'Hudson', 'Packard', 'Pontiac', 'Oldsmobile', 'Plymouth', 'DeSoto', 'Tucker',
  'Saturn', 'Mercury', 'Hummer', 'Eagle', 'Imperial', 'DeLorean', 'Stutz',

  // British Brands
  'Rolls-Royce', 'Aston Martin', 'McLaren', 'MINI', 'MG', 'Lotus',
  'Morgan', 'TVR', 'Caterham', 'Noble', 'Bristol', 'Jensen', 'Triumph', 'Austin',
  'Morris', 'Vauxhall', 'Riley', 'Sunbeam', 'Rover', 'BAC', 'Radical', 'Ariel',

  // Swedish Brands
  'Volvo', 'Polestar', 'Koenigsegg', 'Saab',

  // Spanish Brands
  'Hispano-Suiza', 'Pegaso', 'GTA Motor', 'Tramontana',

  // Romanian Brands
  'Dacia',

  // Other European Brands
  // Dutch
  'Spyker', 'Donkervoort', 'DAF', 'Vandenbrink',
  // Croatian
  'Rimac', 'DOK-ING',
  // Austrian
  'KTM', 'Magna Steyr',
  // Swiss
  'Rinspeed', 'Monteverdi', 'Sbarro',
  // Danish
  'Zenvo',
  // Belgian
  'Minerva', 'Gillet',
  // Russian
  'Lada', 'UAZ', 'GAZ', 'ZIL', 'Moskvitch', 'Aurus',

  // Luxury/Performance/Limited Production
  'Pagani', 'W Motors', 'Pininfarina', 'Gordon Murray',
  'Hennessey', 'Czinger', 'SCG (Glickenhaus)', 'Apollo', 'Dallara', 'Ginetta',
  'Zenvo', 'Hispano Suiza', 'Isdera', 'Lykan', 'Vector', 'Arrinera',

  // Electric Vehicle Startups
  'Arrival', 'Faraday Future', 'Lordstown', 'Nikola', 'Workhorse', 'Lightning',
  'Sono Motors', 'Uniti', 'Aiways', 'Byton', 'Hozon', 'Leapmotor', 'WM Motor',
  'Lightyear', 'Piëch', 'Togg'
].sort();

const uniqueBrandSuggestions = [...new Set(brandSuggestions)];

const MobileOnlineBadge = styled(OnlineBadge)`
  @media (max-width: 640px) {
    bottom: -2px;
    right: -2px;
    font-size: 8px;
    padding: 2px 4px;
    
    &::before {
      width: 3px;
      height: 3px;
    }
  }
`;

const MobileMiniBubble = styled(motion.button)`
  display: none;
  
  @media (max-width: 640px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      -45deg,
      ${props => props.theme.palette.primary.dark},
      ${props => props.theme.palette.primary.main},
      ${props => props.theme.palette.primary.light},
      ${props => props.theme.palette.primary.main}
    );
    background-size: 300% 300%;
    animation: ${gradientShift} 5s ease infinite,
               ${glowPulse} 3s infinite,
               ${pulseCircle} 3s ease-in-out infinite;
    border-radius: 50% 50% 5px 50%;
    transform: rotate(-45deg);
    width: 56px;
    height: 56px;
    padding: 0;
    position: relative;
    cursor: pointer;
    z-index: 998;
    border: none;
    box-shadow: 
      0 8px 32px ${props => `${props.theme.palette.action.shadowLight}`},
      0 0 0 1px ${props => `${props.theme.palette.primary.main}30`};
  }

  &:hover {
    transform: translateY(-4px) rotate(-45deg);
    box-shadow: 
      0 12px 48px ${props => `${props.theme.palette.action.shadowLight}`},
      0 0 0 2px ${props => props.theme.palette.primary.main};
    animation: ${gradientShift} 3s ease infinite,
               ${glowPulse} 3s infinite,
               ${pulseCircle} 3s ease-in-out infinite;
  }

  &:active {
    transform: translateY(0) rotate(-45deg);
  }
`;

const MobileQuickActions = styled(motion.div)`
  display: none;
  
  @media (max-width: 640px) {
    display: flex;
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: 8px;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
  }
`;

const QuickActionButton = styled(motion.button)`
  display: flex;
  align-items: center;
  background: ${props => props.theme.palette.background.paper};
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: 100px;
  padding: 8px 16px;
  font-size: 14px;
  gap: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  white-space: nowrap;
  color: ${props => props.theme.palette.text.primary};
  
  &:hover {
    background: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    border-color: transparent;
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FormLabel = styled.label`
  font-size: 14px;
  color: ${props => props.theme.palette.text.secondary};
  margin-left: 4px;
`;

const FloatingMessage = styled(motion.div)`
  position: absolute;
  bottom: 100%;
  right: 10px;
  background: ${props => props.theme.palette.background.paper};
  border-radius: 24px;
  padding: 24px;
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.12),
    0 4px 16px rgba(0, 0, 0, 0.08),
    inset 0 0 0 1px ${props => `${props.theme.palette.primary.main}20`};
  max-width: 340px;
  min-width: 300px;
  margin-bottom: 12px;
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  z-index: 996;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  transform-origin: bottom right;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    transform: translateY(-3px) scale(1.02);
    box-shadow: 
      0 20px 48px rgba(0, 0, 0, 0.18),
      0 8px 24px rgba(0, 0, 0, 0.12),
      0 0 0 2px ${props => props.theme.palette.primary.main};
    background: linear-gradient(
      135deg,
      ${props => props.theme.palette.background.paper},
      ${props => `${props.theme.palette.primary.main}08`}
    );
  }

  &:active {
    transform: translateY(-1px) scale(0.98);
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -8px;
    right: 24px;
    width: 16px;
    height: 16px;
    background: inherit;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transform: rotate(45deg);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
  }

  animation: ${pulse} 3s infinite;

  @media (max-width: 640px) {
    position: fixed;
    bottom: 148px;
    right: 20px;
    margin-bottom: 0;
    padding: 20px;
    max-width: calc(100% - 80px);
    min-width: 0;
    gap: 12px;
  }
`;

const MessageContent = styled.div`
  flex: 1;
  position: relative;
  padding-right: ${props => props.hasAction ? '40px' : '0'};

  &::after {
    content: '${props => props.hasAction ? '↗ Clicca per iniziare la chat' : ''}';
    position: absolute;
    right: 0;
    bottom: -24px;
    font-size: 13px;
    color: ${props => props.theme.palette.primary.main};
    opacity: 0;
    transition: all 0.3s ease;
    font-weight: 600;
    white-space: nowrap;
    background: ${props => props.theme.palette.background.paper};
    padding: 6px 12px;
    border-radius: 100px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transform: translateY(4px);
  }

  ${FloatingMessage}:hover &::after {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MessageText = styled.div`
  color: ${props => props.theme.palette.text.primary};
  font-size: 15px;
  line-height: 1.6;
  margin-right: ${props => props.hasAction ? '24px' : '0'};
  letter-spacing: -0.01em;
  font-weight: 450;
`;

const ChevronIconStyled = styled(ChevronRight)`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.theme.palette.primary.main};
  opacity: 0.7;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  background: ${props => props.theme.palette.background.paper};
  border-radius: 50%;
  padding: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);

  ${FloatingMessage}:hover & {
    opacity: 1;
    transform: translateY(-50%) translateX(4px);
    color: ${props => props.theme.palette.primary.main};
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
  }
`;

const MiaIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
`;

export const QuickOptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-top: 16px;
`;

export const QuickOptionButton = styled(motion.button)`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  background: ${props => props.theme.palette.background.default};
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.palette.action.hover};
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    font-size: 20px;
  }

  .text {
    color: ${props => props.theme.palette.text.primary};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }

  @media (max-width: 640px) {
    padding: 14px 18px;
    
    .icon {
      width: 28px;
      height: 28px;
      font-size: 18px;
    }

    .text {
      font-size: 0.95rem;
    }
  }
`;

export const WelcomeTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 12px;
  color: ${props => props.theme.palette.text.primary};
  font-family: inherit;
`;

export const WelcomeDescription = styled.p`
  color: ${props => props.theme.palette.text.secondary};
  margin-bottom: 16px;
  font-size: 1rem;
  line-height: 1.5;
  font-family: inherit;
  opacity: 0.9;

  @media (max-width: 640px) {
    font-size: 0.95rem;
  }
`;

const CloseMessageButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.palette.text.secondary};
  opacity: 0.6;
  transition: all 0.2s ease;
  border-radius: 50%;

  &:hover {
    opacity: 1;
    background: ${props => props.theme.palette.action.hover};
    color: ${props => props.theme.palette.text.primary};
  }

  @media (max-width: 640px) {
    display: flex !important;
    top: 8px;
    right: 8px;
    padding: 8px;
    z-index: 1001;
    
    width: 32px;
    height: 32px;
  }
`;

const NotificationBadge = styled.div`
  position: absolute;
  top: -6px;
  right: -6px;
  background: linear-gradient(135deg, #ff4b4b, #ff0000);
  color: white;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  padding: 0 6px;
  border: 2px solid ${props => props.theme.palette.background.paper};
  transform-origin: center center;
  animation: ${float} 3s ease-in-out infinite, ${glow} 2s ease-in-out infinite;
  will-change: transform, box-shadow;
  z-index: 2;
  
  @media (max-width: 640px) {
    top: -4px;
    right: -4px;
    min-width: 18px;
    height: 18px;
    font-size: 11px;
    padding: 0 4px;
  }

  @media (max-width: 480px) {
    min-width: 16px;
    height: 16px;
    font-size: 10px;
    border-width: 1.5px;
  }
`;

const MobileBadge = styled(NotificationBadge)`
  display: none;
  @media (max-width: 640px) {
    display: flex;
  }
`;

const MessageTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  
  img {
    height: 24px;
    width: auto;
    border-radius: 50%;
    &[src=""] {
      display: none;
    }
  }
`;

const ToggleChat = ({ isChatVisible, toggleChat, onSendMessage, notification }) => {
  const [floatingMessages, setFloatingMessages] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const notificationSound = React.useRef(null);
  const [audioLoaded, setAudioLoaded] = React.useState(false);

  React.useEffect(() => {
    if (notification) {
      const notificationMessage = {
        text: notification.message_to_send || notification.custom_message,
        message: notification.visible_message || notification.message,
        type: notification.type,
        duration: notification.duration
      };
      
      setFloatingMessages([notificationMessage]);
      setCurrentMessageIndex(0);
      setShowMessage(true);
      
      // Play notification sound when a new notification arrives
      playNotificationSound();
      
      if (notification.duration) {
        const timer = setTimeout(() => {
          setFloatingMessages([]);
          setShowMessage(false);
        }, notification.duration);
        
        return () => clearTimeout(timer);
      }
    }
  }, [notification]);

  React.useEffect(() => {
    // We'll keep only one audio instance for notifications to reuse after user interaction is granted
    try {
      const audio = new Audio('https://files.aitomotivelab.com/notification.mp3');
      audio.preload = 'auto';
      audio.volume = 0.7;

      const handleCanPlay = () => {
        setAudioLoaded(true);
        // Attempt to load the audio data right away
        audio.load();
      };

      const handleError = (e) => {
        console.warn('Audio loading failed:', e);
        setAudioLoaded(false);
      };

      audio.addEventListener('canplaythrough', handleCanPlay);
      audio.addEventListener('error', handleError);

      notificationSound.current = audio;

      return () => {
        audio.removeEventListener('canplaythrough', handleCanPlay);
        audio.removeEventListener('error', handleError);
        audio.pause();
      };
    } catch (error) {
      console.warn('Audio initialization failed:', error);
      setAudioLoaded(false);
    }
  }, []);

  const playNotificationSound = async () => {
    if (!audioLoaded || !notificationSound.current) return;

    try {
      // Instead of new Audio(...) each time, reuse our loaded instance
      // iOS Safari will only let this play if the user has already interacted with the page
      const audio = notificationSound.current;
      // For debugging, you might log the promise result
      const playPromise = audio.play();
      
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          // Most common error on mobile: user hasn't interacted or phone is in silent mode
          if (error.name !== 'NotAllowedError') {
            console.warn('Audio playback failed:', error);
          } else {
            console.log('Audio was blocked by browser—needs user interaction or phone un-muted.');
          }
        });
      }
    } catch (error) {
      console.warn('Audio playback error:', error);
    }
  };

  // This is the "mark user as interacted" effect
  React.useEffect(() => {
    const handleUserInteraction = () => {
      document.documentElement.setAttribute('data-user-interacted', 'true');
      // Attempt to "unlock" audio in iOS Safari by calling play() once here
      if (audioLoaded && notificationSound.current) {
        notificationSound.current.play().catch(() => {
          console.log('Audio still blocked—user or device might be on silent mode.');
        });
      }
      // Remove listeners after first interaction
      ['click', 'touchstart'].forEach(event => {
        document.removeEventListener(event, handleUserInteraction);
      });
    };

    ['click', 'touchstart'].forEach(event => {
      document.addEventListener(event, handleUserInteraction);
    });

    return () => {
      ['click', 'touchstart'].forEach(event => {
        document.removeEventListener(event, handleUserInteraction);
      });
    };
  }, [audioLoaded]);

  const handleCloseMessage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMessage(false);
    setFloatingMessages([]);
  };

  const [showSearch, setShowSearch] = React.useState(() => {
    return localStorage.getItem('hideSearchPrompt') !== 'true';
  });
  const [searchQuery, setSearchQuery] = React.useState('');
  const [showConfigForm, setShowConfigForm] = React.useState(false);
  const [brand, setBrand] = React.useState('');
  const [budget, setBudget] = React.useState('');
  const [isMinimized, setIsMinimized] = React.useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = React.useState([]);
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = React.useState(-1);
  const [showMobileActions, setShowMobileActions] = React.useState(true);
  const [kilometers, setKilometers] = React.useState('');
  const [connectionError, setConnectionError] = React.useState(false);
  const [retryCount, setRetryCount] = React.useState(0);
  const maxRetries = 3;

  const budgetRanges = [
    '< €20.000',
    '€20.000 - €35.000',
    '€35.000 - €50.000',
    '€50.000 - €75.000',
    '> €75.000'
  ];

  const kmRanges = [
    'Meno di 10.000 km',
    'Tra 10.000 e 20.000 km',
    'Tra 20.000 e 30.000 km',
    'Più di 30.000 km'
  ];

  const handleToggleClick = () => {
    if (!isChatVisible) {
      setShowSearch(false);
      trackChatEvent.toggleChat(true);
      setHasUnread(false);
    }
    toggleChat();
  };

  const handleSuggestion = (message) => {
    toggleChat();
    trackChatEvent.toggleChat(true);
    setTimeout(() => {
      onSendMessage(message);
    }, 300);
  };

  const handleConfigSubmit = (e) => {
    e.preventDefault();
    let message = "Vorrei cercare un'auto";
    
    const conditions = [];
    if (brand) {
      conditions.push(`marca ${brand}`);
      trackChatEvent.brandSelect(brand);
    }
    if (budget) conditions.push(`budget ${budget}`);
    if (kilometers) conditions.push(`percorro ${kilometers} all'anno`);
    
    if (conditions.length > 0) {
      message += ` con ${conditions.join(', ')}`;
    }
    
    handleSuggestion(message);
    setShowConfigForm(false);
    setBrand('');
    setBudget('');
    setKilometers('');
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (searchQuery.trim()) {
      handleSuggestion(searchQuery.trim());
      setSearchQuery('');
    }
  };

  const handleMinimize = (e) => {
    e.stopPropagation();
    trackChatEvent.minimizeChat();
    setIsMinimized(true);
    setShowSearch(false);
    localStorage.setItem('hideSearchPrompt', 'true');
  };

  const isMobile = window.innerWidth <= 640;

  const handleBrandChange = (e) => {
    const value = e.target.value;
    setBrand(value);

    if (value.length > 0) {
      const filtered = uniqueBrandSuggestions.filter(
        suggestion => suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
      setSelectedSuggestionIndex(-1);
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const getConfigButtonText = (brand, budget, km) => {
    const parts = [];
    if (brand) parts.push(brand);
    if (budget) parts.push(`${budget}`);
    if (km) parts.push(`${km}`);
    
    return parts.length > 0 
      ? `Cerca ${parts.join(' - ')}` 
      : "Cerca tutte le auto";
  };

  const handleMobileClick = (e) => {
    e.stopPropagation();
    if (e.target.closest('.toggle-arrow')) {
      setShowMobileActions(prev => !prev);
    } else {
      setIsMinimized(false);
      handleToggleClick();
    }
  };

  React.useEffect(() => {
    if (!isChatVisible) {
      setShowSearch(false);
    }
  }, [isChatVisible]);

  React.useEffect(() => {
    const handleWebSocketError = (error) => {
      console.warn('WebSocket connection error:', error);
      setConnectionError(true);
      
      if (retryCount < maxRetries) {
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
        }, 2000 * (retryCount + 1));
      }
    };

    window.addEventListener('websocketerror', handleWebSocketError);
    
    return () => {
      window.removeEventListener('websocketerror', handleWebSocketError);
    };
  }, [retryCount]);

  const getDisplayMessage = () => {
    if (connectionError) {
      return "Mi dispiace, sto avendo problemi di connessione. Riprova tra poco! 🔄";
    }
    const currentMessage = floatingMessages[currentMessageIndex];
    return currentMessage?.text || currentMessage?.message || "";
  };

  const handleMessageClick = (message) => {
    if (message.onClick) {
      message.onClick();
    } else if (message.message) {
      trackChatEvent.toggleChat(true);
      toggleChat();
      setTimeout(() => {
        onSendMessage(message.message);
      }, 300);
      setShowMessage(false);
    }
  };

  React.useEffect(() => {
    // Only track visit count, don't auto-open
    const visitCount = parseInt(localStorage.getItem('visitCount') || '0', 10);
    localStorage.setItem('visitCount', (visitCount + 1).toString());
  }, []);

  return (
    <StyledComponentsThemWrapper>
      <ToggleContainer>
        <AnimatePresence>
          {!isChatVisible && showSearch && !isMinimized && (
            <motion.div
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 20, scale: 0.95 }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              style={{ width: '100%', position: 'relative' }}
            >
              <CloseButton
                onClick={handleMinimize}
                size="small"
                aria-label="close"
              >
                <X size={18} />
              </CloseButton>
              
              <SearchBarContainer>
                <SearchIconStyled size={24} />
                <form onSubmit={handleSearchSubmit} style={{ flex: 1 }}>
                  <SearchContainer>
                    <PlaceholderContainer isHidden={searchQuery.length > 0}>
                      Ciao sono MIA, l'AI che trova l'auto giusta per te
                    </PlaceholderContainer>
                    <SearchInput
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onFocus={() => setShowSearch(true)}
                      onClick={(e) => e.stopPropagation()}
                      aria-label="Campo di ricerca"
                    />
                  </SearchContainer>
                </form>
              </SearchBarContainer>
            </motion.div>
          )}

          {!isChatVisible && (
            <>
              {showMessage && (
                <FloatingMessage
                  initial={{ opacity: 0, y: 10, scale: 0.95 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, y: 10, scale: 0.95 }}
                  transition={{ duration: 0.3, ease: [0.4, 0, 0.2, 1] }}
                  key={currentMessageIndex}
                  onClick={() => {
                    handleMessageClick(floatingMessages[currentMessageIndex]);
                  }}
                  hasAction={!!floatingMessages[currentMessageIndex].message}
                >
                  <CloseMessageButton
                    onClick={handleCloseMessage}
                    aria-label="Close message"
                    onTouchEnd={(e) => {
                      e.preventDefault();
                      handleCloseMessage(e);
                    }}
                  >
                    <X size={16} />
                  </CloseMessageButton>
                  <MessageContent hasAction={!!floatingMessages[currentMessageIndex].message}>
                    <MessageTitle>
                      <img 
                        src="https://files.aitomotivelab.com/Mia_Logo_Black.png"
                        alt="MIA"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = 'https://www.aitomotivelab.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLOGO_MIA.731a2d9a.png&w=1200&q=75';
                        }}
                      />
                    </MessageTitle>
                    <MessageText>
                      {getDisplayMessage()}
                    </MessageText>
                    {floatingMessages[currentMessageIndex].message && (
                      <ChevronIconStyled size={16} />
                    )}
                  </MessageContent>
                </FloatingMessage>
              )}
              
              <MobileMiniBubble
                onClick={handleMobileClick}
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                disabled={connectionError}
              >
                <ChatIconWrapper>
                  <MessageCircle />
                </ChatIconWrapper>
                <Avatar>
                  <MiaLogo />
                </Avatar>
                {hasUnread && <MobileBadge />}
                {!connectionError && <MobileOnlineBadge>online</MobileOnlineBadge>}
              </MobileMiniBubble>
            </>
          )}

          {!isChatVisible && showConfigForm && (
            <SubFormContainer
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 20, scale: 0.95 }}
              transition={{ duration: 0.2 }}
            >
              <FormTitle>Cerca la tua auto ideale</FormTitle>
              <form onSubmit={handleConfigSubmit}>
                <FormGrid>
                  <FormGroup>
                    <FormLabel>Marca</FormLabel>
                    <BrandInputContainer>
                      <BrandInput
                        type="text"
                        placeholder="Inserisci la marca"
                        value={brand}
                        onChange={handleBrandChange}
                        autoComplete="off"
                      />
                      {showSuggestions && filteredSuggestions.length > 0 && (
                        <SuggestionsContainer>
                          {filteredSuggestions.map((suggestion, index) => (
                            <SuggestionItem
                              key={suggestion}
                              selected={index === selectedSuggestionIndex}
                              onClick={() => {
                                setBrand(suggestion);
                                setShowSuggestions(false);
                              }}
                            >
                              {suggestion}
                            </SuggestionItem>
                          ))}
                        </SuggestionsContainer>
                      )}
                    </BrandInputContainer>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Budget</FormLabel>
                    <Select
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                    >
                      <option value="">Seleziona il tuo budget</option>
                      {budgetRanges.map((range) => (
                        <option key={range} value={range}>
                          {range}
                        </option>
                      ))}
                    </Select>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Quanti km percorri all'anno?</FormLabel>
                    <Select
                      value={kilometers}
                      onChange={(e) => setKilometers(e.target.value)}
                    >
                      <option value="">Seleziona il chilometraggio annuale</option>
                      {kmRanges.map((range) => (
                        <option key={range} value={range}>
                          {range}
                        </option>
                      ))}
                    </Select>
                  </FormGroup>
                </FormGrid>

                <SubmitButton type="submit">
                  {getConfigButtonText(brand, budget, kilometers)}
                </SubmitButton>
              </form>
            </SubFormContainer>
          )}
        </AnimatePresence>

        <Tooltip 
          title={connectionError ? "Servizio temporaneamente non disponibile" : "Chiedi a MIA"} 
          placement="left"
        >
          <div style={{ position: 'relative' }}>
            <StyledIconButton
              aria-label="toggle chat"
              onClick={() => {
                if (!connectionError) {
                  setIsMinimized(false);
                  handleToggleClick();
                }
              }}
              size="large"
              disabled={connectionError}
              sx={{ 
                '@media (max-width: 640px)': { display: 'none' },
                opacity: connectionError ? 0.7 : 1,
              }}
            >
              <ChatIconWrapper>
                <MessageCircle />
              </ChatIconWrapper>
              <Avatar>
                <MiaLogo />
              </Avatar>
              {hasUnread && <NotificationBadge />}
              {!connectionError && <OnlineBadge>online</OnlineBadge>}
            </StyledIconButton>
          </div>
        </Tooltip>
      </ToggleContainer>
    </StyledComponentsThemWrapper>
  );
};

export default ToggleChat;
