import {
  Close as CloseIcon,
  SearchRounded as SearchIcon,
  Person as PersonIcon,
  SmartToy as AIIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
  AppBar,
  Tooltip,
} from "@mui/material";
import { motion } from "framer-motion";
import styled from "styled-components";
import { StyledComponentsThemWrapper } from "./StyledComponentsThemWrapper";
import { trackChatEvent } from '../utils/analytics';
import MobileSearchPanel from './MobileSearchPanel';
import { useState } from 'react';

const HeaderContainer = styled(motion.div)`
  position: relative;
  z-index: 10;
`;

const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.v2.chatBackground};
  box-shadow: none;
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: ${props => props.isMobile ? '0' : '12px 12px 0 0'};
  position: ${props => props.isMobile ? 'sticky' : 'static'};
  top: 0;
  left: 0;
  right: 0;
  
  /* Add safe-area-inset-top for iOS */
  padding-top: ${props => props.isMobile ? 'env(safe-area-inset-top, 0px)' : '0'};
  margin-top: 0;
`;

const StyledToolbar = styled(Toolbar)`
  min-height: 56px !important;
  padding: 0 12px !important;
  display: flex;
  justify-content: space-between !important;
  width: 100%;
`;

const AnimatedAvatar = styled(motion.create(Avatar))`
  width: 40px;
  height: 40px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isOnline
      ? props.theme.palette.success.main
      : props.theme.palette.warning.main};
  position: absolute;
  bottom: 0;
  right: 0;
  border: 2px solid ${(props) => props.theme.palette.background.paper};
  animation: ${(props) => (props.isOnline ? "pulse 2s infinite" : "none")};

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0
        ${(props) => `${props.theme.palette.success.main}66`};
    }
    70% {
      box-shadow: 0 0 0 6px
        ${(props) => `${props.theme.palette.success.main}00`};
    }
    100% {
      box-shadow: 0 0 0 0
        ${(props) => `${props.theme.palette.success.main}00`};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.v2.textPrimary};
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const ChatHeader = ({
  isMobile,
  toggleChat,
  toggleChatWidth,
  isChatWide,
  isLoading,
  onSendMessage,
  isHumanControlled,
  humanControlMessage,
  isOnline,
  toggleSearchPanel,
}) => {
  const theme = useTheme();
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);

  return (
    <StyledComponentsThemWrapper>
      <HeaderContainer
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <StyledAppBar position="static" isMobile={isMobile}>
          <StyledToolbar>
            <Box sx={{ 
              display: "flex", 
              alignItems: "center",
              flex: '1',
              minWidth: 0,
            }}>
              <AnimatedAvatar
                src={localStorage.getItem("logo")}
                alt="Chat Logo"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <StatusIndicator isOnline={isOnline} />
              </AnimatedAvatar>
              <Box sx={{ minWidth: 0 }}>
                <Typography variant="h6" color={theme.palette.v2.textPrimary} noWrap>
                  {localStorage.getItem("name_chat")}
                </Typography>
                <Typography variant="caption" noWrap>
                  {isHumanControlled ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PersonIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                      Operatore online
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AIIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                      Assistente AI
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ 
              display: "flex", 
              alignItems: "center", 
              gap: 1.5,
              ml: 2,
              mr: 1.5,
            }}>
              {isMobile ? (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <StyledIconButton
                    onClick={() => setMobileSearchOpen(true)}
                    sx={{
                      backgroundColor: '#ffffff',
                      padding: '8px',
                      borderRadius: '50%',
                      width: '36px',
                      height: '36px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                      '&:hover': {
                        backgroundColor: '#ffffff',
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
                      },
                      transition: 'all 0.2s ease-in-out',
                    }}
                  >
                    <SearchIcon sx={{ 
                      fontSize: '1.3rem', 
                      color: '#1976d2',
                    }} />
                  </StyledIconButton>
                  <StyledIconButton 
                    onClick={() => {
                      trackChatEvent.toggleChat(false);
                      toggleChat();
                    }}
                    sx={{
                      padding: '8px',
                      width: '36px',
                      height: '36px',
                      border: '2px solid rgba(255, 255, 255, 0.2)',
                      borderRadius: '50%',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        border: '2px solid rgba(255, 255, 255, 0.3)',
                      }
                    }}
                  >
                    <CloseIcon sx={{ fontSize: '1.2rem' }} />
                  </StyledIconButton>
                </Box>
              ) : (
                <>
                  <Tooltip 
                    title="Ricerca l'auto adatta a te con l'aiuto della nostra IA"
                    arrow
                    placement="bottom"
                  >
                    <StyledIconButton
                      onClick={() => {
                        console.log("Search icon clicked");
                        toggleSearchPanel?.();
                      }}
                      sx={{
                        backgroundColor: '#ffffff',
                        padding: '8px',
                        borderRadius: '50%',
                        width: '36px',
                        height: '36px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                        '&:hover': {
                          backgroundColor: '#ffffff',
                          transform: 'translateY(-2px)',
                          boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
                        },
                        transition: 'all 0.2s ease-in-out',
                      }}
                    >
                      <SearchIcon sx={{ 
                        fontSize: '1.3rem', 
                        color: '#1976d2',
                      }} />
                    </StyledIconButton>
                  </Tooltip>
                  <StyledIconButton 
                    onClick={() => {
                      trackChatEvent.toggleChat(false);
                      toggleChat();
                    }}
                    sx={{
                      padding: '8px',
                      width: '36px',
                      height: '36px',
                      border: '2px solid rgba(255, 255, 255, 0.2)',
                      borderRadius: '50%',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        border: '2px solid rgba(255, 255, 255, 0.3)',
                      }
                    }}
                  >
                    <CloseIcon sx={{ fontSize: '1.2rem' }} />
                  </StyledIconButton>
                </>
              )}
              <MobileSearchPanel 
                open={mobileSearchOpen}
                onClose={() => setMobileSearchOpen(false)}
              />
            </Box>
          </StyledToolbar>
        </StyledAppBar>
      </HeaderContainer>
    </StyledComponentsThemWrapper>
  );
};

export default ChatHeader;
