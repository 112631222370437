import {
  Send as SendIcon,
  LightbulbOutlined as LightbulbOutlinedIcon,
  Lightbulb as LightbulbIcon,
} from "@mui/icons-material";
import { Box, IconButton, TextField } from "@mui/material";
import { motion } from "framer-motion";
import { useRef, useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { StyledComponentsThemWrapper } from "./StyledComponentsThemWrapper";
import { trackChatEvent } from '../utils/analytics';
import PredictedQuestionChips from "./ChatBody/PredictedQuestionChips";

const FooterContainer = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  z-index: 1000;
  padding-bottom: env(safe-area-inset-bottom, 12px);
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
`;

const ActionButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

const StyledTextField = styled.textarea`
  flex: 1;
  padding: 8px 12px;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  font-size: 16px;
  resize: none;
  max-height: 100px;
  min-height: 40px;
  background: ${({ theme }) => theme.palette.background.paper};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const MobileChatFooter = ({
  onSendMessage,
  isLoading,
  isHumanControlActive,
  inputValue,
  setInputValue,
  predictedQuestions,
}) => {
  const [showQuestions, setShowQuestions] = useState(false);
  const [hasSeenQuestions, setHasSeenQuestions] = useState(false);
  const textAreaRef = useRef(null);
  const previousQuestionsRef = useRef([]);

  useEffect(() => {
    if (JSON.stringify(predictedQuestions) !== JSON.stringify(previousQuestionsRef.current)) {
      setShowQuestions(false);
      previousQuestionsRef.current = predictedQuestions;
    }
  }, [predictedQuestions]);

  const hasNewQuestions = predictedQuestions && 
    predictedQuestions.length > 0 && 
    JSON.stringify(predictedQuestions) !== JSON.stringify(previousQuestionsRef.current);

  const handleSend = () => {
    if (inputValue.trim() && (!isLoading || isHumanControlActive)) {
      trackChatEvent.sendMessage({
        event_owner: "aitomotive",
        page_type: "home",
        comm_type: "chat",
        affiliation: "native",
        element_position: "bottom_right",
        department: "sales",
        affiliation_id: "bot",
        comm_status: "send",
        message_length: inputValue.length,
      });
      onSendMessage(inputValue);
      setInputValue("");
      setShowQuestions(false);
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "auto";
      }
    }
  };

  const handleQuestionClick = (question) => {
    setInputValue(question);
    setShowQuestions(false);
  };

  return (
    <StyledComponentsThemWrapper>
      {showQuestions && predictedQuestions && predictedQuestions.length > 0 && (
        <PredictedQuestionChips
          questions={predictedQuestions}
          onQuestionClick={handleQuestionClick}
          isMobile={true}
        />
      )}
      <FooterContainer>
        <InputContainer>
          <ActionButton
            onClick={() => {
              setShowQuestions(!showQuestions);
              if (!showQuestions) setHasSeenQuestions(true);
            }}
            disabled={isLoading && !isHumanControlActive}
            sx={{
              backgroundColor: showQuestions ? '#e3f2fd' : '#fff',
              color: hasNewQuestions ? '#ffc107' : '#bdbdbd',
            }}
          >
            {hasNewQuestions ? <LightbulbIcon /> : <LightbulbOutlinedIcon />}
          </ActionButton>

          <StyledTextField
            ref={textAreaRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
            placeholder="Scrivi un messaggio..."
            disabled={isLoading && !isHumanControlActive}
          />

          <ActionButton
            onClick={handleSend}
            disabled={isLoading && !isHumanControlActive}
            sx={{
              backgroundColor: "#25D366",
              color: "#fff",
              opacity: inputValue.trim() ? 1 : 0.6,
            }}
          >
            <SendIcon />
          </ActionButton>
        </InputContainer>
      </FooterContainer>
    </StyledComponentsThemWrapper>
  );
};

export default MobileChatFooter; 