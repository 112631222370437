import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  LinearProgress,
  useTheme,
  InputAdornment,
} from "@mui/material";

import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Help as HelpIcon,
  Lock as LockIcon,
  Campaign as CampaignIcon,
  Shield as ShieldIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Save as SaveIcon,
  EventAvailable as EventAvailableIcon,
  Send as SendIcon,
} from "@mui/icons-material";

import { useCallback, useEffect, useMemo, useState } from "react";
import { fetchRegistrationForm, submitFormResponse } from "../../api/api";
import { trackChatEvent } from "../../utils/analytics";
import { countries } from './countries';
import styled from '@emotion/styled';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '8px 14px',
  },
  '& .country-flag': {
    fontSize: '20px',
    lineHeight: 1,
  },
  '& .country-prefix': {
    color: theme.palette.text.secondary,
    fontSize: '14px',
  }
}));

const StepIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  marginTop: '12px',
  marginBottom: '16px',
  padding: '0 16px',

  '& .step-dot': {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[300],
    transition: 'all 0.3s ease',
  },

  '& .step-dot.active': {
    backgroundColor: theme.palette.primary.main,
    transform: 'scale(1.5)',
  },

  '& .step-dot.completed': {
    backgroundColor: theme.palette.success.main,
  }
}));

const PersonalDataForm = ({ onSubmit, onSendMessage, setIsLoading, activeTrigger }) => {
  const [formData, setFormData] = useState(null);
  const [formState, setFormState] = useState({});
  const [originalFormState, setOriginalFormState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [formDisplayCount, setFormDisplayCount] = useState(1);
  const [hasChanges, setHasChanges] = useState(false);
  const theme = useTheme();
  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const [formAnimation, setFormAnimation] = useState(false);
  const [fieldTransition, setFieldTransition] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({ code: 'IT', prefix: '+39', flag: '🇮🇹' });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [focusField, setFocusField] = useState(null);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const response = await fetchRegistrationForm();
      setFormData(response.data);
      initializeFormState(response.data);
    } catch (err) {
      setError("Impossibile recuperare i dati del modulo. Riprova più tardi.");
    }

    const savedFormState = localStorage.getItem("formState");
    const savedIsSubmitted = localStorage.getItem("isSubmitted");

    if (savedFormState) {
      const parsedState = JSON.parse(savedFormState);
      setFormState(parsedState);
      setOriginalFormState(parsedState);
    }
    if (savedIsSubmitted) {
      setIsSubmitted(JSON.parse(savedIsSubmitted));
      setIsEditing(false);
    }

    // Tracking form display count
    const storedCount = parseInt(localStorage.getItem("form_display_count") || "1", 10);
    setFormDisplayCount(storedCount);
    trackChatEvent.formInteraction("personal_data", "form_shown", {
      display_count: storedCount,
    });
    localStorage.setItem("form_display_count", (storedCount + 1).toString());
  };

  const initializeFormState = (data) => {
    const savedFormState = localStorage.getItem("formState");
    if (savedFormState) {
      const parsedState = JSON.parse(savedFormState);
      setFormState(parsedState);
      setIsSubmitted(!!parsedState.id);
      setIsEditing(!parsedState.id);
    } else {
      const initialState = {};
      const desiredFields = ['first_name', 'last_name', 'phone', 'email'];
      
      data.pages.forEach((page) => {
        page.groups.forEach((group) => {
          group.fields
            .filter(field => 
              !field.is_hidden && 
              desiredFields.includes(field.keyword?.toLowerCase())
            )
            .forEach((field) => {
              initialState[field.field_id] = "";
            });
        });
      });
      setFormState(initialState);
      localStorage.setItem("formState", JSON.stringify(initialState));
    }
  };

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const isValidPhone = (phone) => {
    // Accepts numbers with optional '+' prefix, minimum 8 digits
    const re = /^\+?[0-9]{8,}$/;
    return re.test(String(phone).replace(/\s+/g, ''));
  };

  const handleChange = (fieldId, value, fieldType) => {
    setFormState((prevState) => {
      let newState;
      let isValid = true;
      let errorMessage = '';

      // Validate based on field type
      if (fieldType === 'email' && value) {
        isValid = isValidEmail(value);
        errorMessage = isValid ? '' : 'Email non valida';
      } else if (fieldType === 'number' && value) {
        const field = formData?.pages.flatMap(page => 
          page.groups.flatMap(group => 
            group.fields.find(f => f.field_id === fieldId)
          )
        ).filter(Boolean)[0];

        if (field?.keyword?.toLowerCase().includes('phone')) {
          isValid = isValidPhone(value);
          errorMessage = isValid ? '' : 'Numero di telefono non valido';
        }
      }

      if (fieldType === "checkbox") {
        const currentValues = prevState[fieldId] || [];
        const newValues = currentValues.includes(value)
          ? currentValues.filter((v) => v !== value)
          : [...currentValues, value];
        newState = { ...prevState, [fieldId]: newValues };
      } else {
        newState = { 
          ...prevState, 
          [fieldId]: value,
          [`${fieldId}_error`]: errorMessage
        };
      }
      
      setHasChanges(JSON.stringify(newState) !== JSON.stringify(originalFormState));
      localStorage.setItem("formState", JSON.stringify(newState));
      setFocusField(fieldId);
      return newState;
    });
  };

  useEffect(() => {
    if (focusField) {
      const inputToFocus = document.querySelector(
        `[data-field-id="${focusField}"] input, [data-field-id="${focusField}"] textarea`
      );
      if (inputToFocus) {
        inputToFocus.focus();
      }
    }
  }, [focusField]);

  const evaluateCondition = useCallback(
    (conditionsArray) => {
      const evaluateSingleCondition = (condition) => {
        const { fieldId, type, value } = condition;
        const fieldValue = formState[fieldId];

        if (Array.isArray(fieldValue)) {
          // For checkbox fields
          switch (type) {
            case "equals":
              return (
                JSON.stringify(fieldValue.sort()) ===
                JSON.stringify(
                  value
                    .split(",")
                    .map((v) => v.trim())
                    .sort()
                )
              );
            case "includes":
              return fieldValue.includes(value);
            case "not_includes":
              return !fieldValue.includes(value);
            default:
              return false;
          }
        } else {
          // For other fields
          switch (type) {
            case "equals":
              return fieldValue === value;
            case "not_equals":
              return fieldValue !== value;
            case "contains":
              return String(fieldValue).includes(value);
            case "not_contains":
              return !String(fieldValue).includes(value);
            case "greater_than":
              return Number(fieldValue) > Number(value);
            case "less_than":
              return Number(fieldValue) < Number(value);
            default:
              return true;
          }
        }
      };

      let result = true;
      let currentOperator = "AND";

      for (let i = 0; i < conditionsArray.length; i++) {
        const condition = conditionsArray[i];
        const conditionResult = evaluateSingleCondition(condition);

        if (currentOperator === "AND") {
          result = result && conditionResult;
        } else {
          result = result || conditionResult;
        }

        currentOperator = condition.logicalOperator || "AND";

        if (currentOperator === "AND" && !result) break;
        if (currentOperator === "OR" && result) break;
      }

      return result;
    },
    [formState]
  );

  const evaluateFieldConditions = useCallback(
    (conditions) => {
      // Return true for empty, null, or undefined conditions
      if (!conditions || conditions === "[]" || conditions === null || conditions === "") return true;
      
      try {
        // Handle string "null" case
        if (conditions === "null") return true;
        
        // Handle case where conditions is already an array
        if (Array.isArray(conditions)) {
          return conditions.length === 0 ? true : evaluateCondition(conditions);
        }
        
        const parsedConditions = JSON.parse(conditions);
        // Handle empty array case
        if (Array.isArray(parsedConditions) && parsedConditions.length === 0) return true;
        
        return evaluateCondition(parsedConditions);
      } catch (error) {
        console.warn("Error parsing field conditions:", error, "Conditions:", conditions);
        // Return true on parse error to show the field by default
        return true;
      }
    },
    [evaluateCondition]
  );

  const visibleFields = useMemo(() => {
    if (!formData || !formData.pages) return [];
    
    // Define the desired field order using keywords
    const fieldOrder = ['first_name', 'last_name', 'phone', 'email'];
    
    // Get all fields from the form data
    const allFields = formData.pages.flatMap((page) =>
      page.groups.flatMap((group) =>
        group.fields.filter((field) => 
          field && 
          !field.is_hidden && 
          evaluateFieldConditions(field?.conditions)
        )
      )
    );

    // Create an ordered array of fields based on the desired order
    const orderedFields = fieldOrder
      .map(keyword => 
        allFields.find(field => 
          field.keyword?.toLowerCase() === keyword
        )
      )
      .filter(field => field !== undefined); // Remove any undefined fields

    return orderedFields;
  }, [formData, evaluateFieldConditions]);

  const validateForm = useCallback(() => {
    if (!formData) return false;

    const requiredFields = formData.pages.flatMap((page) =>
      page.groups.flatMap((group) =>
        group.fields
          .filter(field => !field.is_hidden)
          .filter(
            (field) => field.required && evaluateFieldConditions(field.conditions)
          )
      )
    );

    const isValid = requiredFields.every((field) => {
      const value = formState[field.field_id];
      return value !== undefined && value !== "" && value.length !== 0;
    });

    setIsFormValid(isValid);
    return isValid;
  }, [formData, formState, evaluateFieldConditions]);

  useEffect(() => {
    validateForm();
  }, [formState, validateForm]);

  const calculateProgress = () => {
    if (!formData) return 0;
    const allFields = formData.pages.flatMap((page) =>
      page.groups.flatMap((group) =>
        group.fields
          .filter(field => !field.is_hidden)
          .filter((field) => evaluateFieldConditions(field.conditions))
      )
    );
    const filledCount = allFields.filter((field) => {
      const val = formState[field.field_id];
      return val && val.length !== 0;
    }).length;
    return (filledCount / allFields.length) * 100;
  };

  const progress = calculateProgress();

  const renderDescription = (description, linkType) => {
    if (!description) return null;

    const parts = description.split(/(https?:\/\/\S+)/gi);
    return parts.map((part, index) => {
      if (part.match(/(https?:\/\/\S+)/gi)) {
        const linkStyle = {
          fontWeight: "bold",
          textDecoration: "underline",
          display: "inline-flex",
          alignItems: "center",
          gap: "4px",
          ...(linkType === "gdpr"
            ? { color: theme.palette.info.main }
            : { color: theme.palette.secondary.main }),
        };

        const icon =
          linkType === "gdpr" ? (
            <LockIcon fontSize="small" />
          ) : (
            <CampaignIcon fontSize="small" />
          );

        return (
          <Link
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            sx={linkStyle}
          >
            {icon}
            {linkType === "gdpr" ? "Link Privacy Policy" : "Link "}
          </Link>
        );
      }
      return part;
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleNextField();
    }
  };

  // Update getCommonProps to improve label visibility
  const getCommonProps = (field, value, hasError, isFirstField, isLastField, disabled) => ({
    fullWidth: true,
    required: field.required,
    label: field.label,
    disabled: disabled,
    value: value,
    onChange: (e) => handleChange(field.field_id, e.target.value, field.field_type),
    onKeyPress: handleKeyPress,
    InputProps: {
      endAdornment: value && !formState[`${field.field_id}_error`] ? (
        <InputAdornment position="end">
          <Button
            onClick={() => isLastField && isFormComplete() ? handleSubmit() : handleNextField()}
            endIcon={isLastField ? <SendIcon sx={{ transform: 'rotate(-45deg)' }} /> : <ArrowForwardIcon />}
            sx={{
              color: isLastField ? '#00e676' : theme.palette.primary.main,
              '&:hover': {
                backgroundColor: isLastField 
                  ? 'rgba(0, 230, 118, 0.1)' 
                  : `${theme.palette.primary.main}10`,
              },
              transition: 'all 0.2s ease',
            }}
          >
            {isLastField ? 'Invia' : 'Avanti'}
          </Button>
        </InputAdornment>
      ) : null
    },
    sx: { 
      '& .MuiInputBase-root': {
        width: '100%',
        height: '56px',
      },
      '& .MuiInputBase-input': {
        height: '24px',
        padding: '16px 14px',
        fontSize: '16px',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: '8px',
          top: 0,
          '& legend': {
            display: 'none',
          },
        },
      },
      '& .MuiInputLabel-root': {
        position: 'relative',
        transform: 'none',
        fontSize: '14px',
        fontWeight: 700,
        marginBottom: '8px',
        color: theme.palette.text.primary,
        '&.Mui-focused': {
          color: theme.palette.primary.main,
        },
      },
      marginBottom: 2,
    },
    variant: "outlined",
    InputLabelProps: {
      shrink: true,
      sx: {
        position: 'static',
        transform: 'none',
        transformOrigin: 'top left',
        fontWeight: 700,
        fontSize: '14px',
        color: theme.palette.text.primary,
        marginBottom: '8px',
        '&.Mui-focused': {
          color: theme.palette.primary.main,
        },
      }
    }
  });

  const renderField = (field) => {
    const value = formState[field.field_id] || "";
    const errorMessage = formState[`${field.field_id}_error`];
    const hasError = Boolean(errorMessage);
    const disabled = !isEditing;
    const isLastField = currentFieldIndex === visibleFields.length - 1;
    const isFirstField = currentFieldIndex === 0;
    const commonProps = getCommonProps(field, value, hasError, isFirstField, isLastField, disabled);

    // Render phone field with prefix selector
    if (field.keyword === 'phone') {
      return (
        <Box sx={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}>
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            flexDirection: isMobile ? 'column' : 'row' 
          }}>
            <StyledSelect
              value={selectedCountry.code}
              onChange={(e) => {
                const country = countries.find(c => c.code === e.target.value);
                setSelectedCountry(country);
              }}
              sx={{ 
                minWidth: isMobile ? '100%' : 110,
                '& .MuiSelect-select': {
                  height: '24px',
                  padding: '16px 14px',
                }
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  <span className="country-flag">{country.flag}</span>
                  <span className="country-prefix">{country.prefix}</span>
                </MenuItem>
              ))}
            </StyledSelect>
            <TextField
              {...commonProps}
              type="tel"
              error={!!formState[`${field.field_id}_error`]}
              helperText={formState[`${field.field_id}_error`]}
              placeholder="Numero di telefono"
              inputProps={{ 
                pattern: "[0-9]*", 
                inputMode: "numeric",
              }}
              sx={{
                ...commonProps.sx,
                flex: 1,
                '& .MuiInputBase-input': {
                  height: '24px',
                  padding: '16px 14px',
                  fontSize: '16px',
                  '&::placeholder': {
                    opacity: 0.7,
                  }
                },
              }}
              value={value.replace(selectedCountry.prefix, '')} // Remove prefix from display
              onChange={(e) => {
                const newValue = e.target.value.replace(/[^0-9]/g, '');
                handleChange(field.field_id, `${selectedCountry.prefix}${newValue}`, field.field_type);
              }}
            />
          </Box>
          {currentFieldIndex > 0 && (
            <Typography
              component="button"
              onClick={() => handleNavigate(-1)}
              sx={{
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                fontSize: '12px',
                color: theme.palette.text.secondary,
                mt: 0.5,
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                padding: 0,
                '&:hover': {
                  color: theme.palette.primary.main,
                },
                transition: 'color 0.2s ease',
              }}
            >
              <ArrowBackIcon sx={{ fontSize: 16 }} />
              campo precedente
            </Typography>
          )}
        </Box>
      );
    }

    // For other fields
    return (
      <Box sx={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}>
        <TextField {...commonProps} />
        {currentFieldIndex > 0 && (
          <Typography
            component="button"
            onClick={() => handleNavigate(-1)}
            sx={{
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              fontSize: '12px',
              color: theme.palette.text.secondary,
              mt: 0.5,
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              padding: 0,
              '&:hover': {
                color: theme.palette.primary.main,
              },
              transition: 'color 0.2s ease',
            }}
          >
            <ArrowBackIcon sx={{ fontSize: 16 }} />
            campo precedente
          </Typography>
        )}
      </Box>
    );
  };

  const renderEmailField = (field) => {
    const value = formState[field.field_id] || "";
    const errorMessage = formState[`${field.field_id}_error`];
    const hasError = Boolean(errorMessage);
    const disabled = !isEditing;
    const isLastField = currentFieldIndex === visibleFields.length - 1;
    const isFirstField = currentFieldIndex === 0;

    const commonProps = getCommonProps(field, value, hasError, isFirstField, isLastField, disabled);

    return (
      <TextField
        {...commonProps}
        type="email"
        placeholder="Inserisci la tua email"
        helperText={hasError ? errorMessage : "Inserisci un indirizzo email valido"}
        error={hasError}
        data-field-id={field.field_id}
      />
    );
  };

  const handleCreateSubmit = async () => {
    setIsSubmitting(true);
    setIsLoading(true);
    setError(null);
    try {
      const emailField = formData.pages.flatMap(page => 
        page.groups.flatMap(group => 
          group.fields.find(f => f.field_type === 'email')
        )
      ).filter(Boolean)[0];

      const phoneField = formData.pages.flatMap(page => 
        page.groups.flatMap(group => 
          group.fields.find(f => f.keyword?.toLowerCase().includes('phone'))
        )
      ).filter(Boolean)[0];

      const emailValue = emailField ? formState[emailField.field_id] : '';
      const phoneValue = phoneField ? formState[phoneField.field_id] : '';
      const isEmailAvailable = emailField && !emailField.is_hidden && emailValue;

      const fieldResponses = formData.pages.flatMap((page) =>
        page.groups.flatMap((group) =>
          group.fields.map((field) => {
            // If email is not available and this is an email field, use phone value
            if (field.field_type === 'email' && !isEmailAvailable && phoneValue) {
              return {
                field: field.id,
                value: `${phoneValue}`,
              };
            }
            // If email is not available and this is a phone field, format the value
            if (field.keyword?.toLowerCase().includes('phone') && !isEmailAvailable) {
              return {
                field: field.id,
                value: `${formState[field.field_id] || ''}`,
              };
            }
            // Default case
            return {
              field: field.id,
              value: formState[field.field_id] || '',
            };
          })
        )
      );

      const result = await submitFormResponse(formData.id, fieldResponses, activeTrigger);
      trackChatEvent.formInteraction("personal_data", "form_submitted", {
        display_count: formDisplayCount,
      });

      saveFieldsToLocalStorage();
      setIsSubmitted(true);
      setIsEditing(false);
      setOriginalFormState({ ...formState, id: result.id });
      localStorage.setItem("isSubmitted", JSON.stringify(true));
      localStorage.setItem("formState", JSON.stringify({ ...formState, id: result.id }));
      
      await onSendMessage("Dati personali salvati", null, null, formState);

      setSnackbar({
        open: true,
        message: "Grazie per aver compilato il form! Verrai ricontattato il prima possibile da un nostro consulente!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("Impossibile inviare il modulo. Riprova.");
      trackChatEvent.formInteraction("personal_data", "form_submission_failed", {
        display_count: formDisplayCount,
        error: error.message,
      });
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };

  const handleUpdateSubmit = async () => {
    setIsSubmitting(true);
    setIsLoading(true);
    setError(null);
    try {
      const emailField = formData.pages.flatMap(page => 
        page.groups.flatMap(group => 
          group.fields.find(f => f.field_type === 'email')
        )
      ).filter(Boolean)[0];

      const phoneField = formData.pages.flatMap(page => 
        page.groups.flatMap(group => 
          group.fields.find(f => f.keyword?.toLowerCase().includes('phone'))
        )
      ).filter(Boolean)[0];

      const emailValue = emailField ? formState[emailField.field_id] : '';
      const phoneValue = phoneField ? formState[phoneField.field_id] : '';
      const isEmailAvailable = emailField && !emailField.is_hidden && emailValue;

      const fieldResponses = formData.pages.flatMap((page) =>
        page.groups.flatMap((group) =>
          group.fields.map((field) => {
            // If email is not available and this is an email field, use phone value
            if (field.field_type === 'email' && !isEmailAvailable && phoneValue) {
              return {
                field: field.id,
                value: `${phoneValue}`,
              };
            }
            // If email is not available and this is a phone field, format the value
            if (field.keyword?.toLowerCase().includes('phone') && !isEmailAvailable) {
              return {
                field: field.id,
                value: `${formState[field.field_id] || ''}`,
              };
            }
            // Default case
            return {
              field: field.id,
              value: formState[field.field_id] || '',
            };
          })
        )
      );

      await submitFormResponse(formData.id, fieldResponses, activeTrigger);
      saveFieldsToLocalStorage();

      setIsEditing(false);
      setOriginalFormState({ ...formState });
      localStorage.setItem("formState", JSON.stringify(formState));
      
      await onSendMessage("Dati personali aggiornati", null, null, formState);

      setSnackbar({
        open: true,
        message: "Dati salvati con successo!",
        severity: "success",
      });
      trackChatEvent.saveForm("personal_data");
    } catch (error) {
      console.error("Form update failed:", error);
      setError("Impossibile aggiornare il modulo. Riprova più tardi.");
      setSnackbar({
        open: true,
        message: "Impossibile aggiornare il modulo. Riprova più tardi.",
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };

  const saveFieldsToLocalStorage = () => {
    const fieldsToSave = [
      { keyword: "is_company" },
      { keyword: "first_name" },
      { keyword: "last_name" },
      { keyword: "name" },
      { keyword: "email" },
      { keyword: "phone" },
      { keyword: "gdpr" },
      { keyword: "marketing" },
    ];

    fieldsToSave.forEach((field) => {
      const fieldId = findFieldIdByKeyword(field.keyword);
      if (fieldId) {
        const value = formState[fieldId];
        if (value !== undefined && value !== null) {
          const storageKey = `aitomotivelab_personalData_${field.keyword}`;
          let storageValue = Array.isArray(value)
            ? value.join(",")
            : typeof value === "object"
            ? JSON.stringify(value)
            : String(value);
          localStorage.setItem(storageKey, storageValue);
        }
      }
    });
  };

  const findFieldIdByKeyword = (keyword) => {
    for (const page of formData?.pages || []) {
      for (const group of page.groups) {
        const field = group.fields.find((f) => f.keyword === keyword);
        if (field) {
          return field.field_id;
        }
      }
    }
    return null;
  };

  const isFormComplete = () => {
    if (!formData) return false;
    
    // Get our specific required fields
    const requiredFields = visibleFields.filter(field => field.required);

    // Check if all required fields have valid values
    const isComplete = requiredFields.every(field => {
      const value = formState[field.field_id];
      
      // Check for empty values
      if (!value || value.length === 0) return false;

      // Additional validation for specific field types
      if (field.keyword === 'email') {
        return isValidEmail(value);
      }
      
      if (field.keyword === 'phone') {
        return isValidPhone(value);
      }

      return true;
    });

    if (!isComplete) {
      setSnackbar({
        open: true,
        message: "Completa il form per essere ricontattato!",
        severity: "warning"
      });
    }

    return isComplete;
  };

  const handleSubmit = async (e) => {
    // Make the event parameter optional
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    
    // Check if form is complete before allowing submission
    if (!isFormComplete()) {
      return;
    }

    if (isSubmitted) {
      await handleUpdateSubmit();
    } else {
      await handleCreateSubmit();
    }
  };

  const handleEditToggle = () => {
    if (isEditing) {
      // If currently editing and there are unsaved changes, confirm
      if (hasChanges && !window.confirm("Hai modifiche non salvate. Vuoi scartarle?")) {
        return;
      }
      setFormState(originalFormState);
      setHasChanges(false);
      setIsEditing(false);
    } else {
      // Start editing
      setOriginalFormState({ ...formState });
      setHasChanges(false);
      setIsEditing(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handleNavigate = (direction) => {
    setFieldTransition(true);
    const newIndex = currentFieldIndex + direction;
    if (newIndex >= 0 && newIndex < visibleFields.length) {
      setCurrentFieldIndex(newIndex);
    }
    setTimeout(() => setFieldTransition(false), 300);
  };

  const renderCurrentField = () => {
    const currentField = visibleFields[currentFieldIndex];
    if (!currentField) return null;

    return (
      <Box
        sx={{
          width: '100%',
          px: 2,
          '& .MuiFormControl-root': {
            marginBottom: 2,
          },
          animation: fieldTransition ? 'slideIn 0.3s ease-out' : 'none',
          '@keyframes slideIn': {
            from: { opacity: 0, transform: 'translateX(20px)' },
            to: { opacity: 1, transform: 'translateX(0)' }
          }
        }}
      >
        {renderField(currentField)}
      </Box>
    );
  };

  const handleNextField = () => {
    const currentField = visibleFields[currentFieldIndex];
    const value = formState[currentField.field_id];
    
    if (value && !formState[`${currentField.field_id}_error`]) {
      if (currentFieldIndex < visibleFields.length - 1) {
        setCurrentFieldIndex(currentFieldIndex + 1);
      } else if (isFormComplete()) {
        handleSubmit(); // No need to pass event here
      }
    }
  };

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!formData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        width: 'auto',
        maxWidth: isMobile ? '100%' : '600px',
        minWidth: isMobile ? '100%' : '500px',
        margin: isMobile ? '0' : '8px auto',
        borderRadius: isMobile ? '0' : '12px',
        backgroundColor: '#ffffff',
        boxShadow: isMobile ? 'none' : '0 1px 2px rgba(0,0,0,0.1)',
        overflow: 'hidden',
        boxSizing: 'border-box',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minHeight: isMobile ? '100vh' : 'auto',
      }}
    >
      {/* Form header - more compact */}
      <Box 
        sx={{ 
          p: isMobile ? 1.5 : 2,
          pb: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1,
        }}
      >
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}>
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
            flex: 1,
          }}>
            <Box 
              component="span"
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: isMobile ? 32 : 40,
                height: isMobile ? 32 : 40,
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                flexShrink: 0,
              }}
            >
              <EventAvailableIcon sx={{ fontSize: isMobile ? 20 : 24 }} />
            </Box>
            
            <Box>
              <Typography 
                sx={{ 
                  fontSize: isMobile ? '16px' : '18px',
                  fontWeight: 600,
                  color: 'text.primary',
                  mb: 0.5,
                  lineHeight: 1.2,
                }}
              >
                Vuoi essere ricontattato?
              </Typography>
              <Typography
                sx={{
                  fontSize: isMobile ? '13px' : '14px',
                  color: 'text.secondary',
                  fontWeight: 400,
                  lineHeight: 1.3,
                }}
              >
                Compila il modulo qui sotto per essere contattato da un nostro operatore. 
                Altrimenti continua ad usare la chat per{' '}
                <Link
                  component="button"
                  onClick={() => onSendMessage("Vorrei esplorare la vostra offerta di prodotti e servizi")}
                  sx={{
                    color: 'primary.main',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  scoprire la nostra offerta di prodotti e servizi
                </Link>
                .
              </Typography>
            </Box>
          </Box>

          {isSubmitted && (
            <IconButton
              onClick={handleEditToggle}
              sx={{ 
                ml: 1,
                color: isEditing ? 'primary.main' : 'action.active',
                width: isMobile ? 32 : 40,
                height: isMobile ? 32 : 40,
              }}
            >
              {isEditing ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          )}
        </Box>

        {/* Progress indicator with padding */}
        <Box sx={{ mt: 1.5, mb: 2, px: 2 }}>
          <LinearProgress 
            variant="determinate" 
            value={(currentFieldIndex / (visibleFields.length - 1)) * 100}
            sx={{
              height: 4,
              borderRadius: 2,
              backgroundColor: theme.palette.grey[200],
              '& .MuiLinearProgress-bar': {
                borderRadius: 2,
              },
            }}
          />
          <Typography 
            variant="caption" 
            sx={{ 
              mt: 0.25,
              display: 'block',
              textAlign: 'right',
              color: 'text.secondary',
              fontSize: '11px',
            }}
          >
            {currentFieldIndex + 1} di {visibleFields.length}
          </Typography>
        </Box>
      </Box>

      {/* Form fields */}
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          overflowX: 'hidden', // Prevent horizontal scrollbar
          display: 'flex',
          flexDirection: 'column',
          px: 2, // Add horizontal padding here instead of in individual fields
        }}
      >
        {renderCurrentField()}
      </Box>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PersonalDataForm;
