import React, { useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Checkbox,
  FormGroup,
  Paper,
  Collapse
} from '@mui/material';
import {
  Close as CloseIcon,
  Search as SearchIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { useWebSocketContext } from '../context/Theme';
import { trackChatEvent } from '../utils/analytics';

const questions = [
  {
    id: 'alimentazione',
    question: 'Che tipo di alimentazione stai cercando?',
    cta: 'Seleziona alimentazione',
    options: ['Benzina', 'Diesel', 'Elettrico', 'Ibrido', 'GPL', 'Metano'],
    multiple: true
  },
  {
    id: 'stato',
    question: 'Stai cercando un veicolo nuovo o usato?',
    cta: 'Seleziona stato veicolo',
    options: ['Nuovo', 'Usato'],
    multiple: true
  },
  {
    id: 'budget',
    question: 'Qual è il tuo budget?',
    cta: 'Seleziona budget',
    options: [
      '>10.000',
      '>15.000',
      '>20.000',
      '>25.000',
      '>30.000',
      '>40.000',
      '>55.000',
      '>80.000',
      'Nessun limite'
    ],
    multiple: false
  }
];

const QuestionSection = ({ question, selections, onSelectionChange, expanded, onToggle }) => {
  // Helper function to display selected values
  const getDisplayValue = () => {
    const selected = selections[question.id];
    if (!selected || (Array.isArray(selected) && selected.length === 0)) {
      return question.cta;
    }
    if (Array.isArray(selected)) {
      return selected.join(', ');
    }
    return selected;
  };

  const hasSelection = selections[question.id] && 
    (Array.isArray(selections[question.id]) ? 
      selections[question.id].length > 0 : 
      Boolean(selections[question.id])
    );

  const handleClear = (e) => {
    e.stopPropagation(); // Prevent the button click from triggering the section toggle
    onSelectionChange(question.id, question.multiple ? [] : '');
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
        {question.question}
      </Typography>
      
      {!expanded ? (
        <Box sx={{ position: 'relative' }}>
          <Button
            fullWidth
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}
            onClick={() => onToggle(question.id)}
            sx={{
              justifyContent: 'space-between',
              textAlign: 'left',
              py: 1.5,
              px: 2,
              pr: hasSelection ? 8 : 2, // Add extra padding when clear button is visible
              borderColor: hasSelection ? 'primary.main' : 'rgba(0,0,0,0.12)',
              color: hasSelection ? 'primary.main' : 'text.secondary',
              '&:hover': {
                borderColor: 'primary.main',
                backgroundColor: 'rgba(25, 118, 210, 0.04)',
              },
            }}
          >
            {getDisplayValue()}
          </Button>
          {hasSelection && (
            <IconButton
              size="small"
              onClick={handleClear}
              sx={{
                position: 'absolute',
                right: 40, // Position before the dropdown arrow
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'text.secondary',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.04)',
                },
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      ) : (
        <Collapse in={expanded}>
          <Paper 
            elevation={0} 
            sx={{ 
              p: 2, 
              border: '1px solid rgba(0,0,0,0.12)',
              borderRadius: 1,
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 1 
            }}>
              {hasSelection && (
                <Button
                  size="small"
                  onClick={handleClear}
                  startIcon={<CloseIcon fontSize="small" />}
                  sx={{ color: 'text.secondary' }}
                >
                  Cancella selezione
                </Button>
              )}
            </Box>
            {question.multiple ? (
              <FormGroup>
                {question.options.map((option) => (
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        checked={selections[question.id]?.includes(option) || false}
                        onChange={(e) => {
                          const currentSelections = selections[question.id] || [];
                          if (e.target.checked) {
                            onSelectionChange(question.id, [...currentSelections, option]);
                          } else {
                            onSelectionChange(question.id, currentSelections.filter(item => item !== option));
                          }
                        }}
                      />
                    }
                    label={option}
                  />
                ))}
              </FormGroup>
            ) : (
              <RadioGroup
                value={selections[question.id] || ''}
                onChange={(e) => onSelectionChange(question.id, e.target.value)}
              >
                {question.options.map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            )}
          </Paper>
        </Collapse>
      )}
    </Box>
  );
};

const MobileSearchPanel = ({ open, onClose }) => {
  const { handleSend } = useWebSocketContext();
  const [selections, setSelections] = useState({});
  const [expandedSection, setExpandedSection] = useState(null);

  const handleSelectionChange = (questionId, value) => {
    setSelections(prev => ({
      ...prev,
      [questionId]: value
    }));
  };

  const toggleSection = (sectionId) => {
    setExpandedSection(expandedSection === sectionId ? null : sectionId);
  };

  const handleSearch = () => {
    const activeSelections = Object.entries(selections).filter(([_, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value;
    });
    
    if (activeSelections.length === 0) return;

    const message = constructSearchMessage(activeSelections);
    
    trackChatEvent.searchSubmit(message);
    handleSend(message, null, null, {
      type: 'chat_message',
      sender: 'user',
      preserveCarousel: true
    });

    onClose();
    setSelections({});
    setExpandedSection(null);
  };

  const constructSearchMessage = (selections) => {
    let parts = [];
    
    // Handle alimentazione and stato separately
    const alimentazione = selections.find(([key]) => key === 'alimentazione')?.[1];
    const stato = selections.find(([key]) => key === 'stato')?.[1];
    
    if (alimentazione || stato) {
      let firstPart = 'Cerca un\'auto';
      
      if (alimentazione) {
        firstPart += ` alimentata a ${Array.isArray(alimentazione) 
          ? alimentazione.join(' o ') 
          : alimentazione}`;
      }
      
      if (stato) {
        const statoStr = Array.isArray(stato) 
          ? stato.map(s => s.toLowerCase()).join(' o ') 
          : stato.toLowerCase();
        firstPart += alimentazione ? `, ${statoStr}` : ` ${statoStr}`;
      }
      
      parts.push(firstPart);
    } else {
      parts.push('Cerca un\'auto');
      parts.push('Non importa il tipo di alimentazione o lo stato del veicolo');
    }

    // Handle budget - Convert > to "di" in message
    const budget = selections.find(([key]) => key === 'budget')?.[1];
    if (budget) {
      if (budget === 'Nessun limite') {
        parts.push('non ho problemi di budget');
      } else {
        const formattedBudget = budget.replace('>', 'di');
        parts.push(`ho un budget ${formattedBudget} euro`);
      }
    }

    return parts.join('. ');
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          backgroundColor: '#f5f5f5'
        }
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        height: '100%',
        pt: 'env(safe-area-inset-top, 0px)'
      }}>
        {/* Header */}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          borderBottom: '1px solid rgba(0,0,0,0.1)',
          backgroundColor: 'white'
        }}>
          <Typography variant="h6" sx={{ flex: 1 }}>
            Ricerca Veicolo
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Questions */}
        <Box sx={{ 
          flex: 1, 
          overflow: 'auto', 
          p: 2,
          backgroundColor: 'white' 
        }}>
          {questions.map((q) => (
            <QuestionSection
              key={q.id}
              question={q}
              selections={selections}
              onSelectionChange={handleSelectionChange}
              expanded={expandedSection === q.id}
              onToggle={toggleSection}
            />
          ))}
        </Box>

        {/* Search Button */}
        <Box sx={{ 
          p: 2, 
          borderTop: '1px solid rgba(0,0,0,0.1)', 
          backgroundColor: 'white',
          boxShadow: '0px -4px 8px rgba(0,0,0,0.05)'
        }}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={handleSearch}
            disabled={Object.keys(selections).length === 0}
            sx={{
              py: 1.5,
              borderRadius: 2
            }}
          >
            Cerca
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MobileSearchPanel; 