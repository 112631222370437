import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Box, IconButton, useTheme } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { StyledComponentsThemWrapper } from '../StyledComponentsThemWrapper';

const PredictedQuestionsWrapper = styled(Box)`
  padding: 12px 0;
  border-top: 1px solid ${props => props.theme.palette.v2.divider || props.theme.palette.divider};
  background: ${props => props.theme.palette.v2.chatBackground || props.theme.palette.background.paper};
  position: relative;
  display: flex;
  align-items: center;
  min-height: 44px;
  height: auto;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.05);
  
  ${props => props.isMobile && `
    position: fixed;
    bottom: 64px;
    left: 0;
    right: 0;
    z-index: 999;
    padding: 12px;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  `}
`;

const QuestionsContainer = styled.div`
  position: relative;
  flex: 1;
  margin: 0 40px;
  overflow: hidden;
  height: auto;
  min-height: 32px;
  display: flex;
  justify-content: ${props => props.isChatWide ? 'space-between' : 'center'};
  align-items: center;
  gap: 8px;
`;

const QuestionChip = styled.button`
  white-space: nowrap;
  padding: 6px 12px;
  border-radius: 16px;
  border: 1px solid ${props => props.theme.palette.primary.main}40;
  background: ${props => props.theme.palette.background.paper};
  color: ${props => props.theme.palette.primary.main};
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 13px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transform-origin: center;
  
  ${props => !props.isChatWide && `
    position: absolute;
    opacity: 0;
    transform: translateX(100%) scale(0.9);
    pointer-events: none;
    width: calc(100% - 16px);
    min-width: 200px;
    max-width: 90%;
    overflow: visible;
    white-space: normal;
    height: auto;
    min-height: 32px;
    padding: 8px 16px;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    
    &.active {
      opacity: 1;
      transform: translateX(0) scale(1);
      pointer-events: auto;
      animation: float 3s ease-in-out infinite;
      position: relative;
      
      &:after {
        content: '';
        position: absolute;
        inset: -2px;
        border: 2px solid ${props => props.theme.palette.primary.main}40;
        border-radius: 18px;
        animation: pulse 2s infinite;
      }
    }

    &.prev {
      opacity: 0;
      transform: translateX(-100%) scale(0.9);
    }

    &.next {
      opacity: 0;
      transform: translateX(100%) scale(0.9);
    }
  `}

  ${props => props.isChatWide && `
    position: relative;
    opacity: 1;
    transform: none;
    pointer-events: auto;
    flex: 1;
    max-width: calc((100% - 16px) / 3);
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    animation: appear 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  `}

  &:hover {
    background: ${props => props.theme.palette.primary.main}20;
    border-color: ${props => props.theme.palette.primary.main};
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    color: ${props => props.theme.palette.primary.dark};
  }

  &:active {
    transform: scale(0.95);
  }

  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-4px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes appear {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const NavigationButton = styled(IconButton)`
  position: absolute;
  z-index: 2;
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 16px;
  background: ${props => props.theme.palette.background.paper} !important;
  display: ${props => props.isChatWide ? 'none' : 'flex'};
  padding: 4px;
  margin: 0;
  top: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid ${props => props.theme.palette.primary.main}20;
  
  &.left {
    left: 4px;
  }

  &.right {
    right: 4px;
  }

  &:hover {
    background: ${props => props.theme.palette.primary.main}15 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background: ${props => props.theme.palette.primary.main}25 !important;
  }

  & .MuiSvgIcon-root {
    font-size: 20px;
  }

  animation: slideIn 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(${props => props.className === 'left' ? '-10px' : '10px'});
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const PredictedQuestionChips = ({ questions, onQuestionClick, messages, setIsLoading, isLoading, isMobile }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const autoRotateTimer = useRef(null);
  const containerRef = useRef(null);
  const theme = useTheme();
  
  // Use isChatWide from localStorage or theme to match the chat width state
  const isChatWide = localStorage.getItem('isChatWide') === 'true';

  const questionsArray = questions ? 
    (typeof questions === 'object' && !Array.isArray(questions) ? 
      Object.values(questions).map(q => q?.toString() || '') : 
      Array.isArray(questions) ? 
        questions.map(q => q?.toString() || '') : 
        []
    ) : [];

  useEffect(() => {
    setCurrentIndex(0);
  }, [questions]);

  const startAutoRotate = () => {
    if (autoRotateTimer.current) {
      clearInterval(autoRotateTimer.current);
    }
    
    if (questionsArray.length > 1 && !isChatWide) {
      autoRotateTimer.current = setInterval(() => {
        setCurrentIndex(prev => (prev + 1) % questionsArray.length);
      }, 5000);
    }
  };

  useEffect(() => {
    if (!isChatWide) {
      startAutoRotate();
    }
    return () => {
      if (autoRotateTimer.current) {
        clearInterval(autoRotateTimer.current);
      }
    };
  }, [questionsArray.length, isChatWide]);

  const handleNavigation = (direction) => {
    if (autoRotateTimer.current) {
      clearInterval(autoRotateTimer.current);
    }

    if (direction === 'left') {
      setCurrentIndex(prev => 
        prev === 0 ? questionsArray.length - 1 : prev - 1
      );
    } else {
      setCurrentIndex(prev => 
        (prev + 1) % questionsArray.length
      );
    }

    startAutoRotate();
  };

  const handleQuestionClick = (question) => {
    if (autoRotateTimer.current) {
      clearInterval(autoRotateTimer.current);
    }
    onQuestionClick(question);
    if (!isChatWide) {
      startAutoRotate();
    }
  };

  if (!questionsArray.length || isLoading) return null;

  return (
    <StyledComponentsThemWrapper>
      <PredictedQuestionsWrapper isMobile={isMobile}>
        {!isChatWide && (
          <NavigationButton 
            className="left" 
            size="small" 
            onClick={(e) => {
              e.preventDefault();
              handleNavigation('left');
            }}
            isChatWide={isChatWide}
          >
            <KeyboardArrowLeft />
          </NavigationButton>
        )}
        
        <QuestionsContainer ref={containerRef} isChatWide={isChatWide}>
          {questionsArray.map((question, index) => (
            <QuestionChip
              key={`${question}-${index}`}
              onClick={() => handleQuestionClick(question)}
              className={!isChatWide ? 
                index === currentIndex ? 'active' : 
                index === (currentIndex - 1 + questionsArray.length) % questionsArray.length ? 'prev' : 
                index === (currentIndex + 1) % questionsArray.length ? 'next' : ''
                : ''}
              isChatWide={isChatWide}
            >
              {question}
            </QuestionChip>
          ))}
        </QuestionsContainer>

        {!isChatWide && (
          <NavigationButton 
            className="right" 
            size="small" 
            onClick={(e) => {
              e.preventDefault();
              handleNavigation('right');
            }}
            isChatWide={isChatWide}
          >
            <KeyboardArrowRight />
          </NavigationButton>
        )}
      </PredictedQuestionsWrapper>
    </StyledComponentsThemWrapper>
  );
};

export default PredictedQuestionChips;