// SearchPanel.jsx
// -------------------------------------------------------------
// 1) We remove the text portion from display in the SearchPanel
//    so that only the carousel items appear here.
// 2) The main text remains displayed in the ChatBody (desktop only).
// -------------------------------------------------------------
import React, { useState } from "react";
import { Box, InputBase, IconButton, Typography, Button, Select, MenuItem, Chip, FormControl, InputLabel } from "@mui/material";
import { Search as SearchIcon, Close as CloseIcon, KeyboardDoubleArrowRight, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import SearchPanelCarousel from "./SearchPanelCarousel";
import { useWebSocketContext } from '../context/Theme';
import { trackChatEvent } from '../utils/analytics';
import CustomScrollbar from './CustomScrollbar';
import { styled } from '@mui/material/styles';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

/**
 * For quick demonstration, we just inline the carousels in the panel.
 * In a real app, you might style them or create a dedicated component.
 */
const SearchPanelWrapper = styled(Box)(({ theme }) => ({
  width: "min(800px, 95vw)",
  height: "100%",
  backgroundColor: "#fff",
  borderRadius: "20px",
  boxShadow: "0 8px 24px rgba(0, 0, 0, 0.08)",
  display: "flex",
  flexDirection: "column",
  overflow: "visible",
  position: "relative",
  border: "1px solid",
  borderColor: "rgba(0, 0, 0, 0.1)",
}));

const CollapseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: '45%',
  transform: 'translateY(-50%) translateX(-50%)',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  padding: '12px',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-50%) translateX(-50%) scale(1.1)',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '28px',
  },
  width: '56px',
  height: '56px',
  borderRadius: '50%',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  zIndex: 1000,
  overflow: 'visible',
}));

// Update the filter options to specify which ones are multiple
const filterOptions = {
  alimentazione: {
    options: ['Benzina', 'Diesel', 'Elettrico', 'Ibrido', 'GPL', 'Metano'],
    multiple: true
  },
  stato: {
    options: ['Nuovo', 'Usato'],
    multiple: true
  },
  budget: {
    options: [
      '>10.000',
      '>15.000',
      '>20.000',
      '>25.000',
      '>30.000',
      '>40.000',
      '>55.000',
      '>80.000',
      'Nessun limite'
    ],
    multiple: false
  }
};

const SearchPanel = ({
  onClose,
  searchMessages = [],
  onNavigateSearch,
  currentIndex,
  totalCount,
  isLoading,
  isHumanControlled
}) => {
  const { handleSend } = useWebSocketContext();
  const [filters, setFilters] = useState({
    alimentazione: [],
    stato: [],
    budget: []
  });

  const handleFilterChange = (filterType, values) => {
    const filterConfig = filterOptions[filterType];
    if (!filterConfig.multiple && Array.isArray(values)) {
      // For non-multiple filters, only take the last selected value
      setFilters(prev => ({
        ...prev,
        [filterType]: values.slice(-1)
      }));
    } else {
      setFilters(prev => ({
        ...prev,
        [filterType]: values
      }));
    }
  };

  const getFilterString = () => {
    // Helper function to remove thousand separators
    const removeThousandSeparator = (str) => {
      if (!str) return '';
      return str.replace(/\./g, '');
    };

    let parts = {
      budget: null,
      specs: [],
      other: []
    };

    // Handle alimentazione and stato filters
    const hasAlimentazione = filters.alimentazione?.length > 0;
    const hasStato = filters.stato?.length > 0;
    if (hasAlimentazione || hasStato) {
      let vehicleSpecs = [];
      if (hasAlimentazione) {
        const alimentazioneStr =
          filters.alimentazione.length > 1
            ? filters.alimentazione.join(" o ")
            : filters.alimentazione[0];
        vehicleSpecs.push(`alimentata a ${alimentazioneStr}`);
      }
      if (hasStato) {
        const statoStr =
          filters.stato.length > 1
            ? filters.stato.map(s => s.toLowerCase()).join(" o ")
            : filters.stato[0].toLowerCase();
        vehicleSpecs.push(statoStr);
      }
      if (vehicleSpecs.length > 0) {
        parts.specs.push(`che sia ${vehicleSpecs.join(", ")}`);
      }
    } else {
      // Removed the unnecessary reference to `searchInput`
      // Optionally, you might want to push a default message here if needed.
      // For example: parts.specs.push('non importa il tipo di alimentazione o lo stato del veicolo');
    }

    // Handle budget
    if (filters.budget) {
      let budgetValue = Array.isArray(filters.budget)
        ? filters.budget[0]
        : filters.budget;
      
      if (budgetValue) {
        if (budgetValue === "Nessun limite") {
          parts.budget = 'non ho problemi di budget';
        } else {
          const formattedBudget = removeThousandSeparator(budgetValue.replace(">", "di ")).trim();
          if (formattedBudget) {
            parts.budget = `ho un budget ${formattedBudget} euro`;
          }
        }
      }
    }

    return parts;
  };

  const handleSearch = () => {
    // Check if we have any filters selected
    const hasFilters = Object.values(filters).some(value => 
      Array.isArray(value) ? value.length > 0 : Boolean(value)
    );
    
    if (!hasFilters) {
      return; // Don't proceed if no filters
    }

    const filterParts = getFilterString();
    let message = ["Sto cercando un'auto"]; // Base message since we removed search input
    
    // Add budget right after the initial statement
    if (filterParts.budget) {
      message.push(filterParts.budget);
    }

    // Add specs and other filters
    if (filterParts.specs.length > 0) {
      message.push(...filterParts.specs);
    }
    if (filterParts.other.length > 0) {
      message.push(...filterParts.other);
    }
    
    const finalMessage = message.join(", ");
    
    trackChatEvent.searchSubmit(finalMessage);
    
    handleSend(finalMessage, null, null, {
      type: 'chat_message',
      sender: 'user',
      preserveCarousel: true
    });
  };

  const hasMultiple = totalCount > 1;

  // Filter messages to only show those with car carousel items
  const carouselMessages = searchMessages.filter(
    msg => msg.carouselItems && Array.isArray(msg.carouselItems) && msg.carouselItems.length > 0
  );

  // Update canSearch to only check filters
  const canSearch = () => {
    const hasValidFilters = Object.values(filters).some(arr => arr.length > 0);
    const canSendNewMessage = !isLoading || isHumanControlled;
    return hasValidFilters && canSendNewMessage;
  };

  return (
    <SearchPanelWrapper>
      <CollapseButton
        onClick={onClose}
        aria-label="collapse search panel"
        title="Close search panel"
      >
        <KeyboardDoubleArrowRightIcon />
      </CollapseButton>

      {/* Search Input Area - Now just contains logo and filters */}
      <Box
        sx={{
          p: 2,
          pt: 2.5,
          pb: 2.5,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Logo */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src="https://files.aitomotivelab.com/Mia_Logo_Black.png"
            alt="MIA Logo"
            width="42"
            height="15"
            style={{ marginRight: "8px" }}
          />
        </Box>

        {/* Filters Row with Search Button */}
        <Box sx={{ 
          display: "flex", 
          gap: 2,
          flexWrap: "wrap",
          alignItems: "flex-start"
        }}>
          {Object.entries(filterOptions).map(([filterType, config]) => (
            <FormControl 
              key={filterType} 
              size="small" 
              sx={{ minWidth: 200, flex: 1 }}
            >
              <InputLabel>{filterType.charAt(0).toUpperCase() + filterType.slice(1)}</InputLabel>
              <Select
                multiple={config.multiple}
                value={filters[filterType] || (config.multiple ? [] : '')}
                onChange={(e) => handleFilterChange(filterType, e.target.value)}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {Array.isArray(selected) ? 
                      selected.map((value) => (
                        <Chip key={value} label={value} size="small" />
                      )) :
                      <Typography>{selected}</Typography>
                    }
                  </Box>
                )}
              >
                {config.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
          <IconButton 
            size="large" 
            onClick={handleSearch}
            disabled={!canSearch()}
            sx={{
              backgroundColor: canSearch() ? 'primary.main' : 'grey.300',
              color: 'white',
              '&:hover': {
                backgroundColor: canSearch() ? 'primary.dark' : 'grey.300',
              },
              '&.Mui-disabled': {
                backgroundColor: 'grey.300',
                color: 'grey.500',
              },
              width: '48px',
              height: '48px',
              transition: 'all 0.2s ease',
              alignSelf: 'center',
              marginTop: '3px', // Align with filters
            }}
          >
            <SearchIcon sx={{ fontSize: "1.5rem" }} />
          </IconButton>
        </Box>
      </Box>

      {/* Navigation buttons - Updated padding and margins */}
      {hasMultiple && (
        <Box 
          sx={{ 
            display: "flex", 
            justifyContent: "space-between", 
            p: 1.5,
            px: 4,
            mr: 3,
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            backgroundColor: '#f8f8f8'
          }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={() => onNavigateSearch(-1)}
            disabled={currentIndex <= 0}
            startIcon={<KeyboardArrowLeft />}
            sx={{
              minWidth: '120px',
              gap: '4px',
              '&:not(:disabled)': {
                borderColor: 'primary.main',
                color: 'primary.main',
              }
            }}
          >
            Precedente
          </Button>
          <Typography variant="body2" sx={{ 
            alignSelf: "center",
            fontWeight: 500
          }}>
            Mostrando risultati {currentIndex + 1} di {totalCount}
          </Typography>
          <Button
            size="small"
            variant="outlined"
            onClick={() => onNavigateSearch(1)}
            disabled={currentIndex >= totalCount - 1}
            endIcon={<KeyboardArrowRight />}
            sx={{
              minWidth: '120px',
              gap: '4px',
              '&:not(:disabled)': {
                borderColor: 'primary.main',
                color: 'primary.main',
              }
            }}
          >
            Seguente
          </Button>
        </Box>
      )}

      {/* Results List - Updated CustomScrollbar implementation */}
      <Box sx={{ flex: 1, position: 'relative', overflow: 'hidden' }}>
        <CustomScrollbar>
          <Box
            sx={{
              p: { xs: 1, sm: 2 },
              height: '100%',
            }}
          >
            {carouselMessages.length === 0 ? (
              <Typography variant="body1" color="textSecondary">
                Nessun risultato ancora
              </Typography>
            ) : (
              carouselMessages.map((msg, idx) => (
                <Box
                  key={idx}
                  sx={{
                    backgroundColor: "#ffffff",
                    mb: 2,
                    p: { xs: 1, sm: 2 },
                    borderRadius: "8px",
                    boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
                  }}
                >
                  <SearchPanelCarousel items={msg.carouselItems} />
                </Box>
              ))
            )}
          </Box>
        </CustomScrollbar>
      </Box>
    </SearchPanelWrapper>
  );
};

export default SearchPanel;