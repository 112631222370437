import RefreshIcon from "@mui/icons-material/Refresh";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { Box, Button, CircularProgress, Fade, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { FaFileAlt } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import CustomCarousel from "./CustomCarousel";
import PersonalDataForm from "./PersonalData";
import {
  ButtonContainer,
  ChatContainer,
  CustomButton,
  FileAudio,
  FileContainer,
  FileIcon,
  FileImage,
  FileName,
  FileOther,
  LoadingMessage,
  MessageContainer,
  MessageContent,
  MessageGroup,
  MessagePaper,
  MessageWrapper,
  OuterContainer,
  ToolProgress,
  OptionChipsContainer,
  OptionChip,
  DateDivider,
  DateLabel,
} from "./style";

import {getToolDisplay} from "../../utils/toolDisplayMapping";
import { StyledComponentsThemWrapper } from "../StyledComponentsThemWrapper";
import { trackChatEvent } from "../../utils/analytics";
import { Tooltip } from "@mui/material";
import styled, { keyframes, css } from "styled-components";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SpeedIcon from '@mui/icons-material/Speed';
import EventIcon from '@mui/icons-material/Event';
import BuildIcon from '@mui/icons-material/Build';
import HandymanIcon from '@mui/icons-material/Handyman';
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonIcon from '@mui/icons-material/Person';


const lastMessageGroupId = "last-message-group";
const lastMessageId = "last-message";

const ConnectionStatus = ({
  isConnected,
  isReconnecting,
  reconnectCountdown,
  reconnectAttempts,
  MAX_RECONNECT_ATTEMPTS,
  onManualReconnect,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [dots, setDots] = useState("");

  useEffect(() => {
    if (isReconnecting) {
      setIsVisible(true);
      const dotsInterval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + "." : ""));
      }, 500);
      return () => clearInterval(dotsInterval);
    } else if (!isConnected) {
      setIsVisible(true);
    } else {
      setIsVisible(true);
      const hideTimeout = setTimeout(() => setIsVisible(false), 3000);
      return () => clearTimeout(hideTimeout);
    }
  }, [isConnected, isReconnecting]);

  if (!isVisible || isConnected) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <Box
        sx={{
          p: 2,
          m: 2,
          borderRadius: 2,
          bgcolor: "background.paper",
          boxShadow: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: isConnected
              ? "success.main"
              : isReconnecting
              ? "warning.main"
              : "error.main",
          }}
        >
          {isConnected ? (
            <WifiIcon />
          ) : isReconnecting ? (
            <RefreshIcon className="spin" />
          ) : (
            <WifiOffIcon />
          )}
          <Typography variant="body1">
            {isConnected
              ? "Connected"
              : isReconnecting
              ? "Reconnecting"
              : "Disconnected"}
          </Typography>
        </Box>

        {isReconnecting && (
          <>
            <Typography variant="body2">
              Attempt {reconnectAttempts} of {MAX_RECONNECT_ATTEMPTS}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <CircularProgress size={16} />
              <Typography variant="body2">
                Retrying in {reconnectCountdown}s{dots}
              </Typography>
            </Box>
          </>
        )}

        {!isConnected && !isReconnecting && (
          <Button
            variant="contained"
            size="small"
            startIcon={<RefreshIcon />}
            onClick={onManualReconnect}
          >
            Retry Connection
          </Button>
        )}
      </Box>
    </motion.div>
  );
};

const TitleContainer = styled(motion.div)`
  background: ${props => props.theme.palette.background.paper};
  border-radius: 24px;
  width: 90%;
  max-width: 600px;
  padding: 16px 24px;
  border: 2px solid ${props => props.theme.palette.primary.main}40;
  box-shadow: 0 8px 32px -8px ${props => props.theme.palette.primary.main}15,
              0 4px 16px -8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  height: auto;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1200px) {
    min-height: 100px;
    max-width: 700px;
  }

  @media (max-width: 768px) {
    min-height: 120px;
    max-width: 95%;
  }

  @media (max-width: 480px) {
    min-height: 140px;
    padding: 16px 20px;
  }

  &:hover {
    border-color: ${props => props.theme.palette.primary.main}60;
    box-shadow: 0 12px 48px -12px ${props => props.theme.palette.primary.main}20,
                0 8px 24px -12px rgba(0, 0, 0, 0.15);
  }
`;

const WelcomeTitle = styled(motion.div)`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 500;
  text-align: center;
  padding: 8px 0;
  color: ${props => props.theme.palette.text.primary};
  font-family: 'Roboto', sans-serif;
  width: 100%;
  margin: 0 auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  line-height: 1.4;

  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 8px 0;
  }
`;

const InlineLogoImage = styled.img`
  height: 24px;
  width: auto;
  vertical-align: middle;
  margin: 0 4px;
  display: inline-block;
  
  @media (max-width: 768px) {
    height: 20px;
  }
`;

const TypingText = styled.div`
  display: inline-block;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: center;
  width: 100%;
  
  img {
    transform: translateY(-2px);
  }
`;

const SlidingText = styled(motion.div)`
  white-space: nowrap;
`;

const WelcomeContainer = styled(motion.div)`
  padding: 0px;
  background: transparent;
  color: ${props => props.theme.palette.text.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  font-family: 'Roboto', sans-serif;
  position: relative;
  width: 100%;
  max-width: 1200px;
  transition: all 0.3s ease;
  min-height: fit-content;
  height: auto;
  margin-bottom: 2vh;

  @media (max-width: 768px) {
    margin-bottom: 1vh;
    gap: 24px;
  }
`;

const breatheAnimation = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.4);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(0, 255, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: ${props => props.theme.palette.primary.main}10;
  border: 2px solid ${props => props.theme.palette.primary.main}40;
  transition: all 0.3s ease;
  padding: 12px;

  svg {
    width: 100% !important;
    height: 100% !important;
    font-size: inherit;
    color: ${props => props.theme.palette.primary.main};
    display: block;
  }

  @media (max-width: 768px) {
    width: 40px; // Smaller icons for mobile
    height: 40px;
    padding: 8px;
  }
`;

const CircularButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 16px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }

  .text {
    font-size: clamp(0.75rem, 1.5vw, 0.875rem);
    color: ${props => props.theme.palette.text.primary};
    text-align: center;
    font-weight: 500;
    margin: 0;
    line-height: 1.2;
  }

  @media (max-width: 480px) {
    padding: 12px;
  }
`;

const WelcomeDescription = styled.p`
  color: ${props => props.theme.palette.text.secondary};
  margin-bottom: 16px;
  font-size: 1.125rem;
  line-height: 1.5;
  font-family: inherit;
  opacity: 0.9;

  @media (max-width: 640px) {
    font-size: 1rem;
  }
`;

const QuickOptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 12px;
`;

const QuickOptionButton = styled(motion.button)`
  display: flex;
  align-items: center;
  padding: 12px;
  background: ${props => props.theme.palette.background.default};
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.palette.action.hover};
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    font-size: 16px;
  }

  .text {
    color: ${props => props.theme.palette.text.primary};
    font-size: 14px;
    font-weight: 500;
  }

  @media (max-width: 640px) {
    .text {
      font-size: 13px;
    }
  }
`;

const MiaLogoWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  z-index: 2;
`;

const MiaLogo = styled.img`
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const BubbleButton = styled(motion.button)`
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${(props) => props.theme.palette.background.default};
  border: 1px solid ${(props) => props.theme.palette.divider};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  transition: all 0.2s ease;
  &:hover {
    background: ${(props) => props.theme.palette.action.hover};
  }
`;

const getBubblePosition = (index, total, radius) => {
  const angle = (360 / total) * index;
  const rad = (angle * Math.PI) / 180;
  const x = radius * Math.cos(rad);
  const y = radius * Math.sin(rad);
  return { x, y };
};

const CircularButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 300px;
  padding: 0 16px;
  margin: 0 auto;

  ${props => props.isAppointmentGrid && `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 600px;
  `}

  @media (max-width: 480px) {
    gap: 12px;
    padding: 0 12px;
    ${props => props.isAppointmentGrid && `
      grid-template-columns: repeat(2, minmax(120px, 1fr));
    `}
  }
`;

const ChatBody = forwardRef(({
  messages,
  isLoading,
  locale,
  onSendMessage,
  onWishlistUpdate,
  onSendPersonalData,
  isConnected,
  isAuthenticated,
  isHumanControlled,
  setIsLoading,
  wishlistItems,
  accessToken,
  onSearchOptionClick,
  isMobile,
}, ref) => {
  const [showButtons, setShowButtons] = useState(true);
  const [mimeTypes, setMimeTypes] = useState({});
  const lastHumanControlledIndex = useRef(-1);
  const observerRef = useRef(null);
  const [showWelcome, setShowWelcome] = useState(() => {
    return !localStorage.getItem('chatWelcomeSeen');
  });
  const [welcomeText, setWelcomeText] = useState("");
  const [showFirstMessage, setShowFirstMessage] = useState(true);
  const [messagePhase, setMessagePhase] = useState(1);
  const [showAppointmentOptions, setShowAppointmentOptions] = useState(false);

  const quickOptions = [
    { 
      icon: <EventIcon sx={{ 
        fontSize: 'inherit',
        width: '100%',
        height: '100%',
        display: 'block',
        padding: '2px'
      }} />,
      text: "Prenota un appuntamento",
      message: "appointment_selection",
      tooltip: "Organizziamo un incontro personalizzato in concessionaria con i nostri esperti."
    },
    { 
      icon: <DirectionsCarIcon sx={{ 
        fontSize: 'inherit',
        width: '100%',
        height: '100%',
        display: 'block',
        animation: 'breathing 2s ease-in-out infinite',
        '@keyframes breathing': {
          '0%': { transform: 'scale(1)' },
          '50%': { transform: 'scale(1.1)' },
          '100%': { transform: 'scale(1)' }
        }
      }} />,
      text: "Cerca un'auto",
      message: "Vorrei acquistare un'auto",
      tooltip: "Trova l'auto dei tuoi sogni! Ti aiuterò a scegliere il veicolo perfetto per te in base alle tue esigenze."
    },
    { 
      icon: <SpeedIcon sx={{ 
        fontSize: 'inherit',
        width: '100%',
        height: '100%',
        display: 'block'
      }} />,
      text: "Test drive",
      message: "Vorrei prenotare un test drive",
      tooltip: "Prova l'emozione di guidare la tua prossima auto! Prenota un test drive gratuito."
    }
  ];

  const appointmentOptions = [
    {
      text: "Vendita",
      message: "Vorrei fissare un appuntamento con un consulente alle vendite",
      icon: <PersonIcon sx={{ 
        fontSize: 'inherit',
        width: '100%',
        height: '100%',
        display: 'block'
      }} />
    },
    {
      text: "Officina",
      message: "Vorrei fissare un appuntamento in officina",
      icon: <BuildIcon sx={{ 
        fontSize: 'inherit',
        width: '100%',
        height: '100%',
        display: 'block'
      }} />
    },
    {
      text: "Tagliando",
      message: "Vorrei fissare un appuntamento per il tagliando",
      icon: <HandymanIcon sx={{ 
        fontSize: 'inherit',
        width: '100%',
        height: '100%',
        display: 'block'
      }} />
    },
    {
      text: "Ricambi",
      message: "Vorrei fissare un appuntamento per i ricambi",
      icon: <InventoryIcon sx={{ 
        fontSize: 'inherit',
        width: '100%',
        height: '100%',
        display: 'block'
      }} />
    }
  ];

  const chatContainerRef = useRef(null);

  // Add a ref for the typing indicator
  const typingIndicatorRef = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollToMessage(msgIndex) {
      if (!chatContainerRef.current) return;
      const messageEl = document.getElementById(`chat-msg-${msgIndex}`);
      if (messageEl) {
        messageEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }));

  useEffect(() => {
    setTimeout(() => {
      const lastMessageGroupElement = document?.getElementById(lastMessageGroupId);
      const lastMessageElement = document?.getElementById(lastMessageId);
      
      // Always scroll to the last message if available, otherwise to the last group
      if (lastMessageElement) {
        lastMessageElement.scrollIntoView({ behavior: "smooth" });
      } else if (lastMessageGroupElement) {
        lastMessageGroupElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  }, [messages]);

  useEffect(() => {
    messages.forEach(async (message, index) => {
      if (!message.id) {
        message.id = `message-${index}`;
      }
      if (message.file_url) {
        const mimeType = await checkMimeType(getFullFileUrl(message.file_url));
        setMimeTypes((prev) => ({ ...prev, [message.file_url]: mimeType }));
      }
    });
  }, [messages]);

  useEffect(() => {
    const messageElements = document.querySelectorAll("[data-message-id]");
    messageElements.forEach((el) => {
      if (observerRef.current) {
        observerRef.current.observe(el);
      }
    });

    return () => {
      if (observerRef.current) {
        messageElements.forEach((el) => {
          observerRef.current.unobserve(el);
        });
      }
    };
  }, [messages]);

  useEffect(() => {
    if (isHumanControlled) {
      lastHumanControlledIndex.current = messages.length - 1;
    }
  }, [isHumanControlled, messages]);

  useEffect(() => {
    if (!messages?.length) return;
    
    const lastMessage = messages[messages.length - 1];
    
    if (lastMessage?.sender === 'user' && !lastMessage.loading) {
      const userMessagesCount = messages.filter(msg => 
        msg.sender === 'user' && 
        !msg.loading && 
        msg.text?.trim()
      ).length;
      
      const lastTrackedCount = parseInt(localStorage.getItem('lastTrackedMessageCount') || '0');
      
      if (userMessagesCount > lastTrackedCount) {
        trackChatEvent.messageCount(userMessagesCount);
        localStorage.setItem('lastTrackedMessageCount', userMessagesCount.toString());
      }
    }
  }, [messages]);

  useEffect(() => {
    if (!messages?.length) return;
    
    const lastMessage = messages[messages.length - 1];
    
    if (lastMessage?.sender === 'assistant' && lastMessage.form) {
      trackChatEvent.formProposed('personal_data');
    }
  }, [messages]);

  useEffect(() => {
    if (showWelcome) {
      // First message
      let currentText = "";
      const firstMessage = "Benvenuto nella nostra Concessionaria...";
      let index = 0;

      const firstTypingInterval = setInterval(() => {
        if (index < firstMessage.length) {
          currentText += firstMessage[index];
          setWelcomeText(currentText);
          index++;
        } else {
          clearInterval(firstTypingInterval);
          
          setTimeout(() => {
            setMessagePhase(2);
            currentText = "";
            index = 0;
            
            const secondMessagePart1 = "Sono ";
            const secondMessagePart2 = ", il tuo assistente virtuale! Come posso aiutarti oggi?";
            
            const secondTypingInterval = setInterval(() => {
              if (index < secondMessagePart1.length) {
                currentText += secondMessagePart1[index];
                setWelcomeText(currentText);
                index++;
              } else if (index === secondMessagePart1.length) {
                setWelcomeText(prev => (
                  <>
                    {currentText}
                    <InlineLogoImage 
                      src="https://files.aitomotivelab.com/Mia_Logo_Black.png"
                      alt="MIA"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = 'https://www.aitomotivelab.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLOGO_MIA.731a2d9a.png&w=1200&q=75';
                      }}
                    />
                  </>
                ));
                index++;
              } else if (index <= secondMessagePart1.length + secondMessagePart2.length + 1) {
                const currentIndex = index - secondMessagePart1.length - 1;
                setWelcomeText(prev => (
                  <>
                    {secondMessagePart1}
                    <InlineLogoImage 
                      src="https://files.aitomotivelab.com/Mia_Logo_Black.png"
                      alt="MIA"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = 'https://www.aitomotivelab.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLOGO_MIA.731a2d9a.png&w=1200&q=75';
                      }}
                    />
                    {secondMessagePart2.slice(0, currentIndex + 1)}
                  </>
                ));
                index++;
              } else {
                clearInterval(secondTypingInterval);
                
                setTimeout(() => {
                  setMessagePhase(3);
                  currentText = "";
                  index = 0;
                  const thirdMessage = "Fai click su una delle icone o scrivimi quello di cui hai bisogno per iniziare una conversazione!";
                  
                  const thirdTypingInterval = setInterval(() => {
                    if (index < thirdMessage.length) {
                      currentText += thirdMessage[index];
                      setWelcomeText(currentText);
                      index++;
                    } else {
                      clearInterval(thirdTypingInterval);
                    }
                  }, 50);
                }, 1000);
              }
            }, 50);
          }, 1000);
        }
      }, 50);

      return () => clearInterval(firstTypingInterval);
    }
  }, [showWelcome]);

  // Add useEffect to handle auto-scrolling when loading state changes
  useEffect(() => {
    if (isLoading && isMobile && typingIndicatorRef.current) {
      setTimeout(() => {
        typingIndicatorRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 100);
    }
  }, [isLoading, isMobile]);

  const handleCleanChat = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleOptionSelect = (option) => {
    setShowButtons(false);
    trackChatEvent.sendMessage(option.length);
    
    // If it's the search option and we have the callback, trigger it
    if (option === "Vorrei acquistare un'auto") {
      console.log("Search icon clicked from welcome message");
      onSearchOptionClick?.();
    }
    
    onSendMessage(option);
  };

  const getFullFileUrl = useCallback((fileUrl) => {
    if (fileUrl.startsWith("blob:")) {
      return fileUrl;
    }
    const domain = process.env.REACT_APP_DOMAIN || "";
    return `${domain}${fileUrl}`;
  }, []);

  const getFileType = useCallback((fileUrl) => {
    let extension;
    if (fileUrl.startsWith("blob:")) {
      extension = "blob";
    } else {
      const urlWithoutQuery = fileUrl.split("?")[0];
      extension = urlWithoutQuery.split(".").pop().toLowerCase();
    }

    if (["jpg", "jpeg", "png", "gif", "webp", "blob"].includes(extension)) {
      return "image";
    } else if (["mp3", "wav", "ogg", "m4a", "aac"].includes(extension)) {
      return "audio";
    } else {
      return "other";
    }
  }, []);

  const checkMimeType = useCallback(async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const contentType = response.headers.get("Content-Type");
      return contentType;
    } catch (error) {
      console.error("Error checking MIME type:", error);
    }
  }, []);

  const renderFile = useCallback(
    (fileUrl, fileName) => {
      console.log("[DEBUG] Access token from props:", !!accessToken);
      
      const fullFileUrl =fileUrl;
      let fileType = getFileType(fileUrl);
      const mimeType = mimeTypes[fileUrl];

      // Use accessToken prop directly, not redeclaring it
      const urlWithToken = fullFileUrl;

      console.log("[DEBUG] File URL constructed:", urlWithToken,mimeType, fileType );

      if (fileType && fileType.includes("audio")) {
        fileType = "audio";
      } else if (fileType && fileType.includes("image")) {
        fileType = "image";
      } else {
        fileType = "other";
      }

      switch (fileType) {
        case "audio":
          return (
            <FileAudio controls>
              <source 
                src={urlWithToken} 
                type={mimeType || "audio/mpeg"} 
                onError={(e) => {
                  console.error("[DEBUG] Audio load error. Token present:", !!accessToken);
                }}
              />
              Your browser does not support the audio element.
            </FileAudio>
          );
        case "image":
          return (
            <FileImage 
              src={urlWithToken} 
              alt={fileName}
              onError={(e) => {
                console.error("[DEBUG] Image load error. Token present:", !!accessToken);
              }}
            />
          );
        default:
          return (
            <FileOther>
              <FileIcon>
                <FaFileAlt />
              </FileIcon>
              <FileName>{fileName}</FileName>
            </FileOther>
          );
      }
    },
    [getFullFileUrl, getFileType, mimeTypes, accessToken] // Add accessToken to dependencies
  );
  // Updated renderMessage function
  const renderMessage = useCallback(
    (message, index) => {
      const isVisible = true;

      if (message.type === "predicted_questions") {
        return null;
      }

      return (
        <Fade in={isVisible} timeout={500}>
          <MessageWrapper
            key={index}
            sender={message.sender}
            data-message-id={message.id}
          >
            <MessageContent>
              {((message.loading && !isHumanControlled) ||
                message.text ||
                message.file_url ||
                message.form) && (
                <MessagePaper sender={message.sender}>
                  {message.loading && !isHumanControlled ? (
                    <LoadingMessage ref={typingIndicatorRef}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 2,
                        width: '100%',
                        justifyContent: 'space-between',
                        paddingBottom: isMobile ? '80px' : '0' // Add padding at bottom for mobile
                      }}>
                        <div className="typing-indicator">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                        {message.tool && message.tool.length > 0 && (
                          <Typography variant="body2" color="textSecondary">
                            {Array.isArray(message.tool) 
                              ? message.tool.map(tool => getToolDisplay(tool)).join(", ")
                              : getToolDisplay(message.tool)}
                          </Typography>
                        )}
                      </Box>
                    </LoadingMessage>
                  ) : (
                    <>
                      {message.text && (
                        <ReactMarkdown>{message.text}</ReactMarkdown>
                      )}
                      {message.file_url && (
                        <FileContainer>
                          {renderFile(message.file_url, message.file_name)}
                        </FileContainer>
                      )}
                    </>
                  )}
                </MessagePaper>
              )}
            </MessageContent>
            {message.carouselItems && (
              <CustomCarousel
                items={message.carouselItems}
                messageId={message.id}
                whishlist={wishlistItems}
                onWishlistUpdate={onWishlistUpdate}
                style={{ marginTop: "16px" }}
                onSendMessage={onSendMessage}
              />
            )}
            {(index === messages.length - 1 && 
              !message.loading && 
              !localStorage.getItem("aitomotivelab_personalData_phone") && 
              messages.length >= 2 || 
              message.tool === "customer_registration" ||
              message.form) && (
              <PersonalDataForm
                onSubmit={onSendPersonalData}
                onSendMessage={onSendMessage}
                setIsLoading={setIsLoading}
                activeTrigger={message.form}
              />
            )}
          </MessageWrapper>
        </Fade>
      );
    },
    [
      isHumanControlled,
      renderFile,
      onWishlistUpdate,
      onSendPersonalData,
      onSendMessage,
      wishlistItems,
      setIsLoading,
      messages,
      isMobile
    ]
  );

  // Updated renderMessages function
  const renderMessages = useCallback(() => {
    let currentSender = null;
    let messageGroup = [];
    const groupedMessages = [];

    messages.forEach((message, index) => {
      if (message.sender !== currentSender) {
        if (messageGroup.length > 0) {
          groupedMessages.push(
            <MessageGroup key={`group-${currentSender}-${index}`}>
              <AnimatePresence>
                {messageGroup.map((msg, idx) => (
                  <motion.div
                    key={`${msg.key}-motion`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3, delay: idx * 0.1 }}
                  >
                    {msg}
                  </motion.div>
                ))}
              </AnimatePresence>
            </MessageGroup>
          );
          messageGroup = [];
        }
        currentSender = message.sender;
      }

      const isPersonalDataMessage = message.text === "Dati personali aggiornati" || 
                                   message.text === "Dati personali salvati";

      const shouldRenderMessage = message.text?.length > 0 ||
                                (message.tool && message.tool?.includes("car_stock_search")) ||
                                message.file_url?.length > 0 ||
                                message.type === "predicted_questions";

      if (message.loading && index <= lastHumanControlledIndex.current) {
        return;
      }

      if (!shouldRenderMessage && !(message.loading && !isHumanControlled)) {
        return;
      }

      const messageElement = renderMessage(message, index);

      if (messageElement) {
        if (isPersonalDataMessage) {
          messageGroup.push(
            <div key={index} style={{ display: "none" }}>
              {messageElement}
            </div>
          );
        } else {
          messageGroup.push(messageElement);
        }
      }
    });

    if (messageGroup.length > 0) {
      groupedMessages.push(
        <MessageGroup
          key={`group-${currentSender}-last`}
          id={lastMessageGroupId}
        >
          <AnimatePresence>
            {messageGroup.map((msg, idx) => (
              <motion.div
                key={`${msg.key}-motion`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, delay: idx * 0.1 }}
                id={idx === messageGroup.length - 1 ? lastMessageId : undefined}
              >
                {msg}
              </motion.div>
            ))}
          </AnimatePresence>
        </MessageGroup>
      );
    }

    return groupedMessages;
  }, [messages, isHumanControlled, lastHumanControlledIndex, renderMessage, isMobile]);
  
  const handleQuickOptionClick = (option) => {
    if (option.message === "Vorrei acquistare un'auto" && !isMobile) {
      onSearchOptionClick?.();
    } else if (option.message === "appointment_selection") {
      setShowAppointmentOptions(true);
      return;
    }
    
    onSendMessage(option.message);
    setShowWelcome(false);
    localStorage.setItem('chatWelcomeSeen', 'true');
  };

  const handleAppointmentOptionClick = (message) => {
    onSendMessage(message);
    setShowWelcome(false);
    setShowAppointmentOptions(false);
    localStorage.setItem('chatWelcomeSeen', 'true');
  };

  return (
    <StyledComponentsThemWrapper>
      <OuterContainer>
        <ChatContainer>
          <ConnectionStatus
            isConnected={isConnected}
            isReconnecting={false}
            reconnectCountdown={0}
            reconnectAttempts={0}
            MAX_RECONNECT_ATTEMPTS={5}
            onManualReconnect={() => {}}
          />
          {isConnected && (
            <>
              {showWelcome && messages.length === 0 ? (
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  padding: '16px',
                  boxSizing: 'border-box',
                }}>
                  <WelcomeContainer
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <TitleContainer>
                      <WelcomeTitle>
                        <TypingText>
                          {showAppointmentOptions ? (
                            "Per cosa vorresti richiedere un appuntamento?"
                          ) : (
                            welcomeText
                          )}
                        </TypingText>
                      </WelcomeTitle>
                    </TitleContainer>
                    <CircularButtonsContainer isAppointmentGrid={showAppointmentOptions}>
                      {showAppointmentOptions ? (
                        <>
                          {appointmentOptions.map((option, index) => (
                            <motion.div
                              key={index}
                              initial={{ opacity: 0, scale: 0.5 }}
                              animate={{ 
                                opacity: 1, 
                                scale: 1
                              }}
                              transition={{ duration: 0.3 }}
                            >
                              <CircularButton
                                onClick={() => handleAppointmentOptionClick(option.message)}
                              >
                                <IconWrapper>
                                  {option.icon}
                                </IconWrapper>
                                <span className="text">{option.text}</span>
                              </CircularButton>
                            </motion.div>
                          ))}
                        </>
                      ) : (
                        <>
                          {quickOptions.map((option, index) => (
                            <motion.div
                              key={index}
                              initial={{ opacity: 0, scale: 0.5 }}
                              animate={{ 
                                opacity: 1, 
                                scale: 1
                              }}
                              transition={{ duration: 0.3 }}
                            >
                              <Tooltip 
                                title={option.tooltip}
                                arrow
                                placement="right"
                                enterDelay={200}
                                leaveDelay={200}
                              >
                                <CircularButton
                                  onClick={() => handleQuickOptionClick(option)}
                                >
                                  <IconWrapper>
                                    {option.icon}
                                  </IconWrapper>
                                  <span className="text">{option.text}</span>
                                </CircularButton>
                              </Tooltip>
                            </motion.div>
                          ))}
                        </>
                      )}
                    </CircularButtonsContainer>
                  </WelcomeContainer>
                </Box>
              ) : (
                renderMessages()
              )}
            </>
          )}
        </ChatContainer>
      </OuterContainer>
    </StyledComponentsThemWrapper>
  );
});

export default ChatBody;

